import { CurrencyApi } from '../../services/Api/CurrencyApi';
import { currencyInitialState } from './initialStates';

const CLEAR_CURRENCY = 'CURRENCY/CLEAR';
const SET_CURRENCY_ALL_FIELD = 'CURRENCY/SET_ALL_FIELD';
const SET_CURRENCY_LOADED = 'CURRENCY/SET_LOADED';
const SET_CURRENCY_ID = 'CURRENCY/SET_ID';
const SET_CURRENCY_CURRENCY = 'CURRENCY/SET_CURRENCY';
const SET_CURRENCY_PRICE = 'CURRENCY/SET_PRICE';
const SET_CURRENCY_SYMBOL = 'CURRENCY/SET_SYMBOL';

const initialState = currencyInitialState

export const getCurrency = (id) => {
  return (dispatch) =>
    CurrencyApi.getCurrencyId(id).then((res) => {
      const newData = { ...res, loaded: true };
      dispatch(setCurrencyAllField(newData));
    });
};
export const clearCurrency = () => {
  return { type: CLEAR_CURRENCY, payload: true };
};
export const setCurrencyLoaded = () => {
  return { type: SET_CURRENCY_LOADED, payload: true };
};
export const setCurrencyAllField = (data) => {
  return { type: SET_CURRENCY_ALL_FIELD, payload: data };
};
export const setCurrencyId = (id) => {
  return { type: SET_CURRENCY_ID, payload: id };
};
export const setCurrencyCurrency = (currency) => {
  return { type: SET_CURRENCY_CURRENCY, payload: currency };
};
export const setCurrencyPrice = (price) => {
  return { type: SET_CURRENCY_PRICE, payload: price };
};
export const setCurrencySymbol = (symbol) => {
  return { type: SET_CURRENCY_SYMBOL, payload: symbol };
};

const currency = (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENCY_LOADED:
      return { ...state, loaded: action.payload };
    case SET_CURRENCY_ALL_FIELD:
      return { ...state, ...action.payload };
    case SET_CURRENCY_ID:
      return { ...state, id: action.payload };
    case SET_CURRENCY_CURRENCY:
      return { ...state, currency: action.payload };
    case SET_CURRENCY_PRICE:
      return { ...state, price: action.payload };
    case SET_CURRENCY_SYMBOL:
      return { ...state, symbol: action.payload };
    case CLEAR_CURRENCY:
      return { ...initialState };
    default:
      return state;
  }
};

export default currency;
