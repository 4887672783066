import React from 'react';
import PaginationPage from '../../../PaginationPage';
import { DESCRIPTION_PAGE, EVENT_AIRPORT } from '../../../../constants/const';
import { AirportApi } from '../../../../services/Api/AirportApi';

const AirportTabs = () => {
  return (
    <div className="tabs-control">
      <PaginationPage
        name={DESCRIPTION_PAGE.airport}
        event={EVENT_AIRPORT}
        request={AirportApi.getAirportPage}
        isImage
        isDetail
      ></PaginationPage>
    </div>
  );
};

export default AirportTabs;
