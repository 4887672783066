import React from 'react';
import PaginationPage from '../../../PaginationPage';
import { EVENT_TAXIS } from '../../../../constants/const';
import { TaxiApi } from '../../../../services/Api/TaxiApi';

const TaxiTabs = () => {
  return (
    <div className="tabs-control">
      <PaginationPage
        name={'Taxi services'}
        event={EVENT_TAXIS}
        request={TaxiApi.getTaxiPage}
        isDetail
      ></PaginationPage>
    </div>
  );
};

export default TaxiTabs;
