import { instance } from "../AuthApi";

export const MarketingApi = {   
  getMarketingPush() {
    return instance.get('admin/marketing/push').then((res) => res.data);
  },

  getMarketingPushId(id) {
    return instance.get('admin/marketing/push/' + id).then((res) => res.data);
  },
  updateMarketingPushId(id, data) {
    return instance
      .put('admin/marketing/push/' + id, data)
      .then((res) => {
        return res.data;
      })
      .catch((e) => e);
  },
  createMarketingPushId(data) {
    return instance
      .post('admin/marketing/push', data)
      .then((res) => {
        return res.data;
      })
      .catch((e) => e);
  },
  deleteMarketingPush(id) {
    return instance
      .delete('admin/marketing/push/' + id)
      .then((res) => {
        return res.data;
      })
      .catch((e) => e);
  }
};
