import { AirportApi } from '../../services/Api/AirportApi';
import { PublicTransportApi } from '../../services/Api/PublicTransportApi';
import { separateUrl } from '../../services/separateUrl';
import { publicTransportInitialState } from './initialStates';

const CLEAR_PUBLIC_TRANSPORT = 'PUBLIC_TRANSPORT/CLEAR';
const SET_PUBLIC_TRANSPORT_ALL_FIELD = 'PUBLIC_TRANSPORT/SET_ALL_FIELD';
const SET_PUBLIC_TRANSPORT_LOADED = 'PUBLIC_TRANSPORT/SET_LOADED';
const SET_PUBLIC_TRANSPORT_ID = 'PUBLIC_TRANSPORT/SET_ID';
const SET_PUBLIC_TRANSPORT_AIRPORT = 'PUBLIC_TRANSPORT/SET_AIRPORT';
const SET_PUBLIC_TRANSPORT_BY_TICKET_URL = 'PUBLIC_TRANSPORT/SET_BY_TICKET_URL';
const SET_PUBLIC_TRANSPORT_COLOR = 'PUBLIC_TRANSPORT/SET_COLOR';
const SET_PUBLIC_TRANSPORT_DESCRIPTION = 'PUBLIC_TRANSPORT/SET_DESCRIPTION';
const SET_PUBLIC_TRANSPORT_FREQUENCY_MAXIMUM = 'PUBLIC_TRANSPORT/SET_FREQUENCY_MAXIMUM';
const SET_PUBLIC_TRANSPORT_FREQUENCY_MINIMUM = 'PUBLIC_TRANSPORT/SET_FREQUENCY_MINIMUM';
const SET_PUBLIC_TRANSPORT_IMAGE_PATH = 'PUBLIC_TRANSPORT/SET_IMAGE_PATH';
const SET_PUBLIC_TRANSPORT_IMAGE_PATH_FILE = 'PUBLIC_TRANSPORT/SET_IMAGE_PATH_FILE';
const SET_PUBLIC_TRANSPORT_JOURNEY_TIME_MAXIMUM = 'PUBLIC_TRANSPORT/SET_JOURNEY_TIME_MAXIMUM';
const SET_PUBLIC_TRANSPORT_JOURNEY_TIME_MINIMUM = 'PUBLIC_TRANSPORT/SET_JOURNEY_TIME_MINIMUM';
const SET_PUBLIC_TRANSPORT_NAMES = 'PUBLIC_TRANSPORT/SET_NAMES';
const SET_PUBLIC_TRANSPORT_ORDERING = 'PUBLIC_TRANSPORT/SET_ORDERING';
const SET_PUBLIC_TRANSPORT_PRICE_IS_FIXED = 'PUBLIC_TRANSPORT/SET_PRICE_IS_FIXED';
const SET_PUBLIC_TRANSPORT_PRICE_VALUE = 'PUBLIC_TRANSPORT/SET_PRICE_VALUE';
const SET_PUBLIC_TRANSPORT_SUB_NAMES = 'PUBLIC_TRANSPORT/SET_SUB_NAMES';
const SET_PUBLIC_TRANSPORT_TAGS = 'PUBLIC_TRANSPORT/SET_TAGS';
const SET_PUBLIC_TRANSPORT_TYPE = 'PUBLIC_TRANSPORT/SET_TYPE';

const initialState = publicTransportInitialState;

export const getPublicTransport = (id) => {
  return (dispatch) =>
    PublicTransportApi.getPublicTransportId(id).then((res) => {
      AirportApi.getAirportId(res.airportId).then((res) => {
        dispatch(setPublicTransportAirport(res));
      });
      const newData = { ...res, loaded: true };
      if (newData.names === null) newData.names = {};
      if (newData.subNames === null) newData.subNames = {};
      if (newData.descriptions === null) newData.descriptions = {};
      if (newData.tags === null) newData.tags = [];
      newData.buyTicketUrl = separateUrl(newData.buyTicketUrl);
      dispatch(setPublicTransportAllField(newData));
    });
};
export const clearPublicTransport = () => {
  return { type: CLEAR_PUBLIC_TRANSPORT };
}; 
export const setPublicTransportAllField = (data) => {
  return { type: SET_PUBLIC_TRANSPORT_ALL_FIELD, payload: data };
};
export const setPublicTransportLoaded = (condition) => {
  return { type: SET_PUBLIC_TRANSPORT_LOADED, payload: condition };
};
export const setPublicTransportId = (id) => {
  return { type: SET_PUBLIC_TRANSPORT_ID, payload: id };
};
export const setPublicTransportAirport = (airport) => {
  return { type: SET_PUBLIC_TRANSPORT_AIRPORT, payload: airport };
};
export const setPublicTransportByTickedUrl = (url) => {
  return { type: SET_PUBLIC_TRANSPORT_BY_TICKET_URL, payload: url };
};
export const setPublicTransportColor = (color) => {
  return { type: SET_PUBLIC_TRANSPORT_COLOR, payload: color };
};
export const setPublicTransportDescription = (descriptions) => {
  return { type: SET_PUBLIC_TRANSPORT_DESCRIPTION, payload: descriptions };
};
export const setPublicTransportFrequencyMaximum = (frequencyMaximum) => {
  return { type: SET_PUBLIC_TRANSPORT_FREQUENCY_MAXIMUM, payload: frequencyMaximum };
};
export const setPublicTransportFrequencyMinimum = (frequencyMinimum) => {
  return { type: SET_PUBLIC_TRANSPORT_FREQUENCY_MINIMUM, payload: frequencyMinimum };
};
export const setPublicTransportImagePath = (imagePath) => {
  return { type: SET_PUBLIC_TRANSPORT_IMAGE_PATH, payload: imagePath };
};
export const setPublicTransportImagePathFile = (imagePathFile) => {
  return { type: SET_PUBLIC_TRANSPORT_IMAGE_PATH_FILE, payload: imagePathFile };
};
export const setPublicTransportJourneyTimeMaximum = (journeyTimeMaximum) => {
  return { type: SET_PUBLIC_TRANSPORT_JOURNEY_TIME_MAXIMUM, payload: journeyTimeMaximum };
};
export const setPublicTransportJourneyTimeMinimum = (journeyTimeMinimum) => {
  return { type: SET_PUBLIC_TRANSPORT_JOURNEY_TIME_MINIMUM, payload: journeyTimeMinimum };
};
export const setPublicTransportNames = (names) => {
  return { type: SET_PUBLIC_TRANSPORT_NAMES, payload: names };
};
export const setPublicTransportOrdering = (ordering) => {
  return { type: SET_PUBLIC_TRANSPORT_ORDERING, payload: ordering };
};
export const setPublicTransportPriceIsFixed = (priceIsFixed) => {
  return { type: SET_PUBLIC_TRANSPORT_PRICE_IS_FIXED, payload: priceIsFixed };
};
export const setPublicTransportPriceValue = (priceValue) => {
  return { type: SET_PUBLIC_TRANSPORT_PRICE_VALUE, payload: priceValue };
};
export const setPublicTransportSubNames = (subNames) => {
  return { type: SET_PUBLIC_TRANSPORT_SUB_NAMES, payload: subNames };
};
export const setPublicTransportTags = (tags) => {
  return { type: SET_PUBLIC_TRANSPORT_TAGS, payload: tags };
};
export const setPublicTransportType = (type) => {
  return { type: SET_PUBLIC_TRANSPORT_TYPE, payload: type };
};

const publicTransport = (state = initialState, action) => {
  switch (action.type) {
    case SET_PUBLIC_TRANSPORT_ALL_FIELD:
      return { ...state, ...action.payload };
    case SET_PUBLIC_TRANSPORT_LOADED:
      return { ...state, loaded: action.payload };
    case SET_PUBLIC_TRANSPORT_ID:
      return { ...state, id: action.payload };
    case SET_PUBLIC_TRANSPORT_AIRPORT:
      return { ...state, airport: action.payload };
    case SET_PUBLIC_TRANSPORT_BY_TICKET_URL:
      return { ...state, buyTicketUrl: action.payload };
    case SET_PUBLIC_TRANSPORT_COLOR:
      return { ...state, color: action.payload };
    case SET_PUBLIC_TRANSPORT_DESCRIPTION:
      return { ...state, descriptions: action.payload };
    case SET_PUBLIC_TRANSPORT_FREQUENCY_MAXIMUM:
      return { ...state, frequencyMaximum: action.payload };
    case SET_PUBLIC_TRANSPORT_FREQUENCY_MINIMUM:
      return { ...state, frequencyMinimum: action.payload };
    case SET_PUBLIC_TRANSPORT_IMAGE_PATH:
      return { ...state, imagePath: action.payload };
    case SET_PUBLIC_TRANSPORT_IMAGE_PATH_FILE:
      return { ...state, imagePathFile: action.payload };
    case SET_PUBLIC_TRANSPORT_JOURNEY_TIME_MAXIMUM:
      return { ...state, journeyTimeMaximum: action.payload };
    case SET_PUBLIC_TRANSPORT_JOURNEY_TIME_MINIMUM:
      return { ...state, journeyTimeMinimum: action.payload };
    case SET_PUBLIC_TRANSPORT_NAMES:
      return { ...state, names: action.payload };
    case SET_PUBLIC_TRANSPORT_ORDERING:
      return { ...state, ordering: action.payload };
    case SET_PUBLIC_TRANSPORT_PRICE_IS_FIXED:
      return { ...state, priceIsFixed: action.payload };
    case SET_PUBLIC_TRANSPORT_PRICE_VALUE:
      return { ...state, priceValue: action.payload };
    case SET_PUBLIC_TRANSPORT_SUB_NAMES:
      return { ...state, subNames: action.payload };
    case SET_PUBLIC_TRANSPORT_TAGS:
      return { ...state, tags: action.payload };
    case SET_PUBLIC_TRANSPORT_TYPE:
      return { ...state, type: action.payload };
    case CLEAR_PUBLIC_TRANSPORT:
      return { ...initialState };
    default:
      return state;
  }
};

export default publicTransport;
