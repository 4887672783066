import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import './style.scss';
import {
  clearAirportMap,
  getAirportMap,
  setAirportMapImageFile,
  setAirportMapLoaded,
  setAirportMapName,
  setAirportMapType
} from '../../../../redux/reducers/airportMaps';
import InputDetail from '../../../DetailComponent/InputDetail';
import ImageLoadDetail from '../../../ImageComponent';
import BlockDetail from '../../../DetailComponent/BlockDetail';
import ResponseAlert from '../../../DetailComponent/ResponseAlert';
import HeaderDetail from '../../../DetailComponent/HeaderDetail';
import BreadcrumbNavigate from '../../../Breadcrumbs';
import { COMPONENT_VARIANT, EVENT_AIRPORT_MAP } from '../../../../constants/const';
import Loader from '../../../Loader';
import { MultipartWrapper } from '../../../../services/MultipartWrapper';
import { useNavi } from '../../../../services/hooks/useNavi';
import { ROUTE_AIRPORT } from '../../../../constants/routes';
import Button from 'react-bootstrap/Button';
import { AirportMapApi } from '../../../../services/Api/AirportMapApi';

const AirportMapDetail = (props) => {
  const airportMap = useSelector((state) => state.airportMaps);
  const navi = useNavi();
  const [dataChanged, setDataChanged] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    setDataChanged(false);
    dispatch(setAirportMapLoaded(false));
    if (props.id === 'new') {
      dispatch(clearAirportMap());
      dispatch(setAirportMapLoaded(true));
    }
    if ((!airportMap.id || parseInt(props.id) !== airportMap.id) && props.id !== 'new')
      dispatch(getAirportMap(parseInt(props.id)));
    else {
      dispatch(setAirportMapLoaded(true));
    }
  }, [props.id]);

  const checkFill = (item) => {
    return !item || item === '';
  };

  const checkValidate = () => {
    if (checkFill(airportMap.name)) return false;
    return true;
  };

  const [response, setResponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [validateName, setValidateName] = useState(false);
  const [validateMap, setValidateMap] = useState(false);
  const validate = () => {
    setValidateName(false);
    setValidateMap(false);

    let flag = true;
    if (!airportMap.name) {
      setValidateName(true);
      flag = false;
    }
    if (!airportMap.image && !airportMap.imageFile) {
      setValidateMap(true);
      flag = false;
    }

    return flag;
  };

  const updateAirportMap = () => {
    let multipart = { ...airportMap };
    const formData = MultipartWrapper.AirportMap(multipart);
    if (validate() && !isLoading) {
      setIsLoading(true);
      if (!airportMap.id)
        AirportMapApi.createAirportMap(formData).then((res) => {
          setDataChanged(false);
          if (res.id) navigate('/' + EVENT_AIRPORT_MAP + '/' + res.id);
          setResponse(res);
          setIsLoading(false);
        });
      else {
        AirportMapApi.updateAirportMap(airportMap.id, formData).then((res) => {
          setDataChanged(false);
          setResponse(res);
          setIsLoading(false);
        });
      }
    }
  };

  return (
    <Loader
      loading={!airportMap.loaded}
      width={'80px'}
      height={'80px'}
      borderWidth={'10px'}
      variant={COMPONENT_VARIANT.dark}>
      <div className="airport-map-detail">
        {!props.isModal && (
          <BlockDetail>
            <BreadcrumbNavigate name={airportMap.name} />
          </BlockDetail>
        )}
        <div className="airport-map-detail-context">
          <HeaderDetail
            name={airportMap.name}
            isValidate={checkValidate()}
            updated={airportMap.updated}
            responsible={airportMap.responsible}
            requestUpdate={updateAirportMap}
            requestDelete={() => {
              AirportMapApi.deleteAirportMap(airportMap.id);
            }}
            loading={isLoading}
          />
          <ResponseAlert response={response} />
          <BlockDetail>
            <ImageLoadDetail
              header={'Airport map photo'}
              defaultImage={airportMap.image}
              validate={validateMap}
              imageDetail={airportMap.imageFile}
              setImageDetail={(value) => {
                setDataChanged(true);
                dispatch(setAirportMapImageFile(value));
              }}
              isNecessarily
              isUnsplash
            />
            <InputDetail
              head="Name"
              placeholder={'Name'}
              validate={validateName}
              defaultValue={airportMap.name}
              setField={(value) => {
                setDataChanged(true);
                dispatch(setAirportMapName(value));
              }}
              isInput
            />
            <InputDetail
              head="Map Type"
              defaultValue={airportMap.mapType}
              setField={(value) => {
                dispatch(setAirportMapType(value));
              }}
              isInput
              isDisabled
            />

            <div className="input-detail">
              <div className="head-context">
                <div className="head">Linked to</div>
              </div>
              <Button
                variant="link"
                style={{ padding: 0 }}
                onClick={() => {
                  navi.pushRoute(
                    ROUTE_AIRPORT + '/' + airportMap.airport?.id,
                    airportMap.airport?.id,
                    airportMap.airport?.iataCode,
                    dataChanged
                  );
                }}>
                {airportMap.airport?.iataCode}
              </Button>
            </div>
          </BlockDetail>
        </div>
      </div>
    </Loader>
  );
};

export default AirportMapDetail;
