import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './style.scss';

import Loader from '../../../Loader';
import BlockDetail from '../../../DetailComponent/BlockDetail';
import BreadcrumbNavigate from '../../../Breadcrumbs';
import HeaderDetail from '../../../DetailComponent/HeaderDetail';
import {
  COMPONENT_VARIANT,
  EVENT_AIRPORT,
  EVENT_PUBLIC_TRANSPORT,
  PUBLIC_TRANSPORT_DROPDOWN_ELEMENT,
  PUBLIC_TRANSPORT_TAG_ELEMENT
} from '../../../../constants/const';
import ResponseAlert from '../../../DetailComponent/ResponseAlert';

import {
  clearPublicTransport,
  getPublicTransport,
  setPublicTransportAirport,
  setPublicTransportByTickedUrl,
  setPublicTransportColor,
  setPublicTransportDescription,
  setPublicTransportFrequencyMaximum,
  setPublicTransportFrequencyMinimum,
  setPublicTransportImagePath,
  setPublicTransportImagePathFile,
  setPublicTransportJourneyTimeMaximum,
  setPublicTransportJourneyTimeMinimum,
  setPublicTransportLoaded,
  setPublicTransportNames,
  setPublicTransportOrdering,
  setPublicTransportPriceIsFixed,
  setPublicTransportPriceValue,
  setPublicTransportSubNames,
  setPublicTransportTags,
  setPublicTransportType
} from '../../../../redux/reducers/publicTransport';
import InputDetail from '../../../DetailComponent/InputDetail';
import ImageLoadDetail from '../../../ImageComponent';
import { ROUTE_AIRPORT, ROUTE_PUBLIC_TRANSPORT_ADD_AIRPORT } from '../../../../constants/routes';
import { useNavi } from '../../../../services/hooks/useNavi';
import InputColorPicker from '../../../DetailComponent/InputColorPicker';
import InputMarkdown from '../../../DetailComponent/InputMarkdown';
import { useNavigate } from 'react-router-dom';
import InputDropDown from '../../../DetailComponent/InputDropDown';
import CardDetail from '../../../DetailComponent/CardDetail';
import CardChoice from '../../../DetailComponent/CardChose';
import { MultipartWrapper } from '../../../../services/MultipartWrapper';
import ModalDetail from '../../../DetailComponent/ModalDetail';
import TransportImagePage from '../../../DetailComponent/TransportImagePage';
import { PublicTransportApi } from '../../../../services/Api/PublicTransportApi';
import { AirportApi } from '../../../../services/Api/AirportApi';

const PublicTransportDetail = (props) => {
  const publicTransport = useSelector((state) => state.publicTransport);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pushRoute } = useNavi();
  const [dataChanged, setDataChanged] = useState(false);
  const [isDeleteImage, setIsDeleteImgae] = useState(false);

  useEffect(() => {
    setDataChanged(false);
    dispatch(setPublicTransportLoaded(false));
    if (props.detailId === 'new') {
      dispatch(clearPublicTransport());
      dispatch(setPublicTransportNames({}));
      dispatch(setPublicTransportSubNames({}));
      dispatch(setPublicTransportDescription({}));
      dispatch(setPublicTransportLoaded(true));
    }
    if (
      (!publicTransport.id || parseInt(props.detailId) !== publicTransport.id) &&
      props.detailId !== 'new'
    ) {
      dispatch(clearPublicTransport());
      dispatch(setPublicTransportNames({}));
      dispatch(setPublicTransportSubNames({}));
      dispatch(setPublicTransportDescription({}));
      dispatch(getPublicTransport(parseInt(props.detailId)));
    } else {
      dispatch(setPublicTransportLoaded(true));
    }
  }, [props.detailId]);

  const [response, setResponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const checkFill = (item) => {
    return !item || item === '';
  };

  const checkValidate = () => {
    if (checkFill(publicTransport.title)) return false;
    if (checkFill(publicTransport.descriptions['en'])) return false;
    return true;
  };

  const [, setValidateCode] = useState(false);
  const validate = () => {
    setValidateCode(false);

    let flag = true;
    if (
      !publicTransport.names['en'] ||
      !publicTransport.descriptions['en'] ||
      !publicTransport.airport ||
      !publicTransport.color
    ) {
      setValidateCode(true);
      flag = false;
    }
    return flag;
  };
  const getValidateText = () => {
    var text = '';
    if (isLoading) return 'Publishes data download please try again later';
    if (!publicTransport.names['en']) text += 'Empty names "en" language. | ';
    if (!publicTransport.airport) text += 'No select airport. | ';
    if (!publicTransport.color) text += 'No select color. | ';
    if (!publicTransport.descriptions['en']) text += 'Empty description "en" language.';
    return text;
  };

  const updatePublicTransport = () => {
    let multipart = { ...publicTransport, isDeleteImage: isDeleteImage };
    const formData = MultipartWrapper.PublicTransport(multipart);
    if (validate() && !isLoading) {
      setIsLoading(true);
      if (!publicTransport.id)
        PublicTransportApi.createPublicTransport(formData).then((res) => {
          if (res.payload) navigate('/' + EVENT_PUBLIC_TRANSPORT + '/' + res.payload);
          setDataChanged(false);
          setResponse(res);
          setIsLoading(false);
        });
      else {
        PublicTransportApi.updatePublicTransport(publicTransport.id, formData).then((res) => {
          setDataChanged(false);
          setResponse(res);
          setIsLoading(false);
        });
      }
    } else {
      setResponse({
        message: getValidateText(),
        status: 400,
        response: { response: { data: 'http://' } }
      });
    }
  };

  return (
    <Loader
      loading={!publicTransport.loaded}
      width={'80px'}
      height={'80px'}
      borderWidth={'10px'}
      variant={COMPONENT_VARIANT.dark}>
      <div className="public-transport-detail">
        {!props.isModal && (
          <BlockDetail>
            <BreadcrumbNavigate name={'Public transport'} />
          </BlockDetail>
        )}
        <div className="public-transport-content">
          <HeaderDetail
            name={'Public transport'}
            isValidate={checkValidate()}
            requestUpdate={updatePublicTransport}
            requestDelete={() => {
              PublicTransportApi.deletePublicTransport(publicTransport.id);
            }}
            loading={isLoading}
            isDisabledChecked
            isDisabledDelete
          />
          <ResponseAlert response={response} />
          <BlockDetail>
            <div className="public-transport-data-context">
              <div className="public-transport-data">
                <InputDropDown
                  head="Category"
                  defaultValue={publicTransport.type}
                  setField={(value) => {
                    dispatch(setPublicTransportType(value));
                  }}
                  listValue={PUBLIC_TRANSPORT_DROPDOWN_ELEMENT}
                  isNecessarily
                />
                <InputDetail
                  head="Name"
                  defaultValue={publicTransport.names}
                  setField={(value) => {
                    dispatch(setPublicTransportNames(value));
                  }}
                  isLanguage
                  isInput
                />
                <InputDetail
                  head="Subtitle"
                  defaultValue={publicTransport.subNames}
                  setField={(value) => {
                    dispatch(setPublicTransportSubNames(value));
                  }}
                  isLanguage
                  isInput
                />
              </div>
              <div className="public-transport-data">
                <ImageLoadDetail
                  header={'Public transport photo'}
                  defaultImage={publicTransport.imagePath}
                  imageDetail={publicTransport.imagePathFile}
                  setImageDetail={(value) => {
                    setDataChanged(true);
                    dispatch(setPublicTransportImagePathFile(value));
                  }}
                  deleteImage={() => {
                    setIsDeleteImgae(true);
                    dispatch(setPublicTransportImagePath(null));
                  }}
                  isDelete
                />
              </div>
            </div>
          </BlockDetail>
          <BlockDetail>
            <div className="public-transport-block-header">Linked Airport</div>
            {publicTransport.airport ? (
              <CardDetail
                headerPhoto={publicTransport.airport.city.smallPhoto}
                title={publicTransport.airport.names.en}
                text={publicTransport.airport.iata}
                onClickDetail={() => {
                  pushRoute(
                    ROUTE_AIRPORT + '/' + publicTransport.airport.id,
                    publicTransport.airport.id,
                    publicTransport.airport.iata,
                    dataChanged
                  );
                }}
                removeField={() => {
                  dispatch(setPublicTransportAirport(null));
                }}
              />
            ) : (
              <CardChoice
                name={'Add airport'}
                event={EVENT_AIRPORT}
                request={AirportApi.getAirportPage}
                setValue={(value) => {
                  AirportApi.getAirportId(value.id).then((res) => {
                    dispatch(setPublicTransportAirport(res));
                  });
                }}
                onNavigate={() => {
                  pushRoute(
                    ROUTE_PUBLIC_TRANSPORT_ADD_AIRPORT + '/' + publicTransport.id,
                    publicTransport.id,
                    'Add airport'
                  );
                }}
              />
            )}
          </BlockDetail>
          <BlockDetail>
            <div className="public-transport-block-header">Details</div>
            <InputDetail
              head="Ordering"
              defaultValue={publicTransport.ordering}
              setField={(value) => {
                dispatch(setPublicTransportOrdering(value));
              }}
              isNumber
              isNecessarily
              isInput
            />
            <InputColorPicker
              head="Color"
              defaultValue={publicTransport.color}
              setField={(value) => {
                dispatch(setPublicTransportColor(value));
              }}
              placeholder={'Not selected'}
              isNecessarily
            />
            <div className="public-transport-cost">
              <InputDetail
                head="Price"
                defaultValue={publicTransport.priceValue}
                setField={(value) => {
                  dispatch(setPublicTransportPriceValue(value));
                }}
                isNumber
                isInput
              />
              <div className="public-transport-price-fixed">
                <input
                  type="checkbox"
                  className="public-transport-price-fixed-checkbox"
                  onChange={() => {
                    dispatch(setPublicTransportPriceIsFixed(!publicTransport.priceIsFixed));
                  }}
                  checked={publicTransport.priceIsFixed}
                />
                Fixed price
              </div>
            </div>
            <div className="public-transport-price">
              <InputDetail
                head="Journey (approx. min)"
                subtitle="min"
                defaultValue={publicTransport.journeyTimeMinimum}
                setField={(value) => {
                  dispatch(setPublicTransportJourneyTimeMinimum(value));
                }}
                isNumber
                isSubtitle
                isInput
              />
              <InputDetail
                subtitle="max"
                defaultValue={publicTransport.journeyTimeMaximum}
                setField={(value) => {
                  dispatch(setPublicTransportJourneyTimeMaximum(value));
                }}
                isNumber
                isSubtitle
                isInput
              />
            </div>
            <div className="public-transport-price">
              <InputDetail
                head="Frequency (every)"
                subtitle="min"
                defaultValue={publicTransport.frequencyMinimum}
                setField={(value) => {
                  dispatch(setPublicTransportFrequencyMinimum(value));
                }}
                isNumber
                isSubtitle
                isInput
              />
              <InputDetail
                subtitle="max"
                defaultValue={publicTransport.frequencyMaximum}
                setField={(value) => {
                  dispatch(setPublicTransportFrequencyMaximum(value));
                }}
                isNumber
                isSubtitle
                isInput
              />
            </div>
            <InputDetail
              head="Buy ticket"
              defaultValue={publicTransport.buyTicketUrl}
              setField={(value) => {
                dispatch(setPublicTransportByTickedUrl(value));
              }}
              isWebsite
              isInput
            />
            <InputDropDown
              head="Tag 1"
              defaultValue={publicTransport.tags[0]}
              setField={(value) => {
                var newTags = [...publicTransport.tags];
                if (value === 'not selected') {
                  newTags = [];
                } else {
                  if (newTags.length === 0) newTags.push(value);
                  else newTags[0] = value;
                }
                dispatch(setPublicTransportTags(newTags));
              }}
              listValue={PUBLIC_TRANSPORT_TAG_ELEMENT}
            />
            <InputDropDown
              head="Tag 2"
              defaultValue={publicTransport.tags[1]}
              setField={(value) => {
                var newTags = [...publicTransport.tags];
                if (value === 'not selected') {
                  newTags = [newTags[0]];
                } else {
                  if (newTags.length <= 1) newTags.push(value);
                  else newTags[1] = value;
                }
                dispatch(setPublicTransportTags(newTags));
              }}
              disabled={publicTransport.tags.length === 0}
              listValue={PUBLIC_TRANSPORT_TAG_ELEMENT}
            />
            <InputDropDown
              head="Tag 3"
              defaultValue={publicTransport.tags[2]}
              setField={(value) => {
                var newTags = [...publicTransport.tags];
                if (value === 'not selected') {
                  newTags = [newTags[0], newTags[1]];
                } else {
                  if (newTags.length <= 2) newTags.push(value);
                  else newTags[2] = value;
                }
                dispatch(setPublicTransportTags(newTags));
              }}
              disabled={publicTransport.tags.length <= 1}
              listValue={PUBLIC_TRANSPORT_TAG_ELEMENT}
            />
          </BlockDetail>
          <BlockDetail>
            <div className="public-transport-block-header">Description</div>
            <div className="public-transport-block-moadal-image">
              <ModalDetail
                name={'Description image'}
                header={'Public transport description image'}
                className={'table-items-button'}
                buttonVariant={COMPONENT_VARIANT.secondary}>
                <TransportImagePage />
              </ModalDetail>
            </div>

            <InputMarkdown
              defaultValue={publicTransport.descriptions}
              setField={(value) => {
                dispatch(setPublicTransportDescription(value));
              }}
              isLanguage
            />
          </BlockDetail>
        </div>
      </div>
    </Loader>
  );
};

export default PublicTransportDetail;
