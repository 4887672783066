import React, { useEffect, useState } from 'react';
import { Badge, Button, Card } from 'react-bootstrap';
import './style.scss';
import { CurrencyApi } from '../../../services/Api/CurrencyApi';

const CardDetail = (props) => {

const [currency, setCurrency] = useState()
  useEffect(() => {
    if(props.currencyId) CurrencyApi.getCurrencyId(props.currencyId).then(res=>setCurrency(res))
  }, [props.currencyId]);
  return (
    <Card className="card-detail">
      {props.headerPhoto && (
        <Card.Img variant="top" src={props.headerPhoto} className="photo-image" />
      )}
      <Card.Body className="card-body">
        <Card.Title className="card-title">{props.title}</Card.Title>
        {props.currencyId ?
          <span className={'card-context'}>
            Currency
            <Badge bg="dark">{(currency?.currency ?? '') + "・" + (currency?.symbol ?? '')}
            </Badge>
          </span>
          : <Card.Text className="card-context">{props.text}</Card.Text>
        }

        <div className="card-button-context">
          <Button
            variant="primary"
            className="card-detail-button"
            onClick={() => {
              props.onClickDetail();
            }}
          >
            Detail
          </Button>
          <Button
            variant="secondary"
            className="card-delete-button"
            onClick={() => {
              props.removeField();
            }}
          >
            Remove field
          </Button>
        </div>
      </Card.Body>
    </Card>
  );
};

export default CardDetail;
