import React, { useEffect, useRef, useState } from 'react';
import { COMPONENT_VARIANT, EVENT_AIRPORT, TIPS_CATEGORY } from '../../../../constants/const';
import InputDetail from '../../../DetailComponent/InputDetail';
import BlockDetail from '../../../DetailComponent/BlockDetail';
import ResponseAlert from '../../../DetailComponent/ResponseAlert';
import HeaderDetail from '../../../DetailComponent/HeaderDetail';
import BreadcrumbNavigate from '../../../Breadcrumbs';
import Loader from '../../../Loader';
import './style.scss';
import { Button, Image, Modal } from 'react-bootstrap';
import CardDetail from '../../../DetailComponent/CardDetail';
import CardChoice from '../../../DetailComponent/CardChose';
import { ROUTE_AIRPORT } from '../../../../constants/routes';
import Upload from '../../../svg/Upload';
import { useNavi } from '../../../../services/hooks/useNavi';
import InputDropDown from '../../../DetailComponent/InputDropDown';
import { TipsApi } from '../../../../services/Api/TipsApi';
import { AirportApi } from '../../../../services/Api/AirportApi';

const TipsDetailNew = (props) => {
  const [airport, setAirport] = useState(null);
  const [text, setText] = useState('');
  const [category, setCategory] = useState(null);
  const [photos, setPhotos] = useState([]);

  const [response, setResponse] = useState(null);

  const refLoadImage = useRef();
  const { pushRoute } = useNavi();

  const [dataChanged, setDataChanged] = useState(false);

  const [modalImageDetail, setModalImageDetail] = useState(null);
  const [modalShow, setModalShow] = useState(false);

  const handleClose = () => setModalShow(false);
  const handleShow = () => setModalShow(true);

  useEffect(() => {
    setDataChanged(false);
  }, []);
  const checkValidate = () => {
    if (checkFill(airport)) return false;
    return true;
  };

  const createTips = () => {
    const formData = new FormData();
    formData.append('text', text);
    formData.append('category', category);
    photos.map((value) => {
      formData.append('photos', value);
    });
    TipsApi.createTips(airport.id, formData).then((res) => {
      if (res.status === 'ok') {
        setResponse({ id: 'ok' });
      } else setResponse(res);
    });
  };

  const pushImage = (value) => {
    if (photos.length >= 4) return;
    const newPhotos = [...photos];
    newPhotos.push(value);
    setPhotos(newPhotos);
  };

  const checkFill = (item) => {
    return !item || item === '';
  };

  const getImageComponent = (image, styleName) => {
    let src = URL.createObjectURL(image);
    return (
      <Image
        src={src}
        className={styleName}
        onClick={() => {
          setModalImageDetail(src);
          handleShow();
        }}
        thumbnail
        fluid
      />
    );
  };

  return (
    <Loader
      loading={false}
      width={'80px'}
      height={'80px'}
      borderWidth={'10px'}
      variant={COMPONENT_VARIANT.dark}>
      <div className="tips-new-detail">
        {!props.isModal && (
          <BlockDetail>
            <BreadcrumbNavigate name={'New Tips'} />
          </BlockDetail>
        )}
        <div className="tips-detail-content">
          <HeaderDetail
            name={'New Tips'}
            isValidate={checkValidate()}
            requestUpdate={() => {
              createTips();
            }}
            requestDelete={() => {}}
            loading={false}
            isDisabledDelete
            isDisabledChecked
          />
          <ResponseAlert response={response} />
          <BlockDetail>
            <div className="tips-detail-data-context">
              <div className="tips-detail-data">
                <InputDetail
                  head="Text"
                  placeholder={'text'}
                  defaultValue={text}
                  setField={(value) => {
                    setText(value);
                  }}
                  isNecessarily
                  isMultiline
                  isInput
                />
                <InputDropDown
                  head="Category"
                  defaultValue={category}
                  setField={(value) => {
                    setCategory(value);
                  }}
                  listValue={TIPS_CATEGORY}
                  isNecessarily
                />
                <div className="tips-photos">
                  <div className="tips-photos-header">Photos</div>
                  <div className="tips-photos-context">
                    {photos?.map((element, key) => {
                      return (
                        <div key={key} className="tips-photos-maps">
                          {getImageComponent(element, 'tips-photos-element')}
                          <Button
                            variant={COMPONENT_VARIANT.primary}
                            className="tips-button-remove"
                            onClick={() => {
                              const newPhotos = photos.filter((value) => value != element);
                              setPhotos(newPhotos);
                            }}>
                            Remove photo
                          </Button>
                        </div>
                      );
                    })}
                  </div>
                  <Button
                    variant="dark"
                    className="tips-button-upload"
                    onClick={() => {
                      refLoadImage.current.click();
                    }}
                    disabled={photos.length >= 4}>
                    <Upload /> Upload
                  </Button>
                  <Modal show={modalShow} onHide={handleClose} className="modal-window">
                    <Modal.Header className="modal-head" closeButton>
                      <Modal.Title>{props.header}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="modal-body">
                      <img className={'tips-photos-modal'} src={modalImageDetail} alt="img" />
                    </Modal.Body>
                    <Modal.Footer>
                      <Button className="footer-button" variant="secondary" onClick={handleClose}>
                        Close
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </div>
              </div>
            </div>
          </BlockDetail>
          <BlockDetail>
            <div className="public-transport-block-header">Linked Airport</div>
            {airport ? (
              <CardDetail
                headerPhoto={airport.cityImage}
                title={airport.otherNames.default ?? airport.otherNames.en}
                text={airport.iataCode}
                onClickDetail={() => {
                  pushRoute(
                    ROUTE_AIRPORT + '/' + airport.id,
                    airport.id,
                    airport.iataCode,
                    dataChanged
                  );
                }}
                removeField={() => {
                  setAirport(null);
                }}
              />
            ) : (
              <CardChoice
                name={'Add airport'}
                event={EVENT_AIRPORT}
                request={AirportApi.getAirportPage}
                setValue={(value) => setAirport(value)}
                onNavigate={() => {}}
              />
            )}
          </BlockDetail>
        </div>
      </div>
      <input
        ref={refLoadImage}
        type="file"
        name="myImage"
        style={{ display: 'none' }}
        accept="image/jpeg,image/gif,image/png,image/webp,image/x-eps"
        onChange={(event) => {
          console.log(event.target.files[0]);
          if (event.target.files[0]) pushImage(event.target.files[0]);
        }}
      />
    </Loader>
  );
};

export default TipsDetailNew;
