import React, { useEffect, useState } from 'react';
import { COMPONENT_VARIANT, FULL_LOCALIZATION_NAME } from '../../../../constants/const';
import { useSelector } from 'react-redux';
import InputDetail from '../../../DetailComponent/InputDetail';
import BlockDetail from '../../../DetailComponent/BlockDetail';
import ResponseAlert from '../../../DetailComponent/ResponseAlert';
import HeaderDetail from '../../../DetailComponent/HeaderDetail';
import BreadcrumbNavigate from '../../../Breadcrumbs';
import Loader from '../../../Loader';
import './style.scss';
import { MultipartWrapper } from '../../../../services/MultipartWrapper';
import ModalConfirm from '../../../ModalConfirm';
import { ReminderApi } from '../../../../services/Api/ReminderApi';

const RemindersDetail = (props) => {
  const languageState = useSelector((state) => state.app.language);
  const [reminder, setReminder] = useState(null);
  const [addReminder, setAddReminder] = useState([]);

  const [reminderEvent, setReminderEvent] = useState(null);
  const [reminderNameRu, setReminderNameRu] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState(null);

  const updateReminder = () => {
    setIsLoading(true);
    let notUpdated = true;
    reminder.map((value) => {
      if (value.updated) {
        notUpdated = false;
        let multipart = { ...value };
        const formData = MultipartWrapper.Reminders(multipart);
        ReminderApi.updateReminderId(value.id, formData).then((res) => {
          setResponse(res);
          setIsLoading(false);
        });
      }
    });
    if (notUpdated) {
      setIsLoading(false);
    }

    if (addReminder.length > 0) {
      setIsLoading(true);
      addReminder.map((value) => {
        ReminderApi.createReminder(value).then((res) => {
          if (res.id) {
            const newReminder = [...reminder];
            newReminder.push(res);
            setReminder(newReminder);
          }
          setResponse(res);
          setIsLoading(false);
        });
      });
      setAddReminder([]);
    }
  };
  const requestDelete = (id, language) => {
    setIsLoading(true);
    ReminderApi.deleteReminder(id).then((res) => {
      let newReminder = [...reminder];
      newReminder = newReminder.filter((val) => val.language !== language);
      setResponse(res);
      setIsLoading(false);
      newReminder.push(res);
      setReminder(newReminder);
    });
  };
  useEffect(() => {
    if (!reminder) {
      if (props.detailId === 'new') setReminder([]);
      else
      ReminderApi.getReminder().then((res) => {
          let resReminder = res;
          const findReminderId = resReminder.find((val) => val.id === parseInt(props.detailId));
          resReminder = resReminder.filter((val) => val.duration === findReminderId.duration);
          const massReminder = resReminder.map((value) => {
            return {
              duration: value.duration,
              id: value.id,
              language: value.language,
              text: value.text,
              title: value.title,
              updated: false
            };
          });
          const languageRuReminder = massReminder.find((val) => val.language === 'ru');
          setReminder(massReminder);
          setReminderEvent(findReminderId.duration);
          setReminderNameRu(languageRuReminder.title);
        });
    }
  }, []);

  const updateLanguageReminder = (language, value, type) => {
    const findReminder = reminder.find((val) => val.language === language);
    const findNewReminder = addReminder.find((val) => val.language === language);
    if (value === '' && !findReminder && findNewReminder) {
      if (
        (findNewReminder.title === '' && type === 'text') ||
        (findNewReminder.text === '' && type === 'title')
      ) {
        const newAddReminder = [...addReminder];
        newAddReminder.filter((val) => val.language !== language);
        setAddReminder(newAddReminder);
      }
    }
    if (!findReminder) {
      if (!findNewReminder) {
        if (value === '') return;
        addReminder.push({
          duration: reminderEvent,
          language: language,
          text: type === 'text' ? value : '',
          title: type === 'title' ? value : ''
        });
        if (language === 'ru' && type === 'title') setReminderNameRu(value);
      } else {
        const newAddReminder = [...addReminder];
        newAddReminder.map((val) => {
          if (val.language === language) {
            if (type === 'title') val.title = value;
            else val.text = value;
            if (language === 'ru' && type === 'title') setReminderNameRu(value);
          }
        });
        setAddReminder(newAddReminder);
      }
    } else {
      const newReminder = [...reminder];
      newReminder.map((val) => {
        if (val.language === language) {
          if (type === 'title') val.title = value;
          else val.text = value;
          if (language === 'ru' && type === 'title') setReminderNameRu(value);
          val.updated = true;
        }
      });
      setReminder(newReminder);
    }
  };

  return (
    <Loader
      loading={!reminder || !languageState}
      width={'80px'}
      height={'80px'}
      borderWidth={'10px'}
      variant={COMPONENT_VARIANT.dark}>
      <div className="push-event-detail">
        {!props.isModal && (
          <BlockDetail>
            <BreadcrumbNavigate name={reminderEvent} />
          </BlockDetail>
        )}
        <div className="push-event-detail-content">
          <HeaderDetail
            name={reminderEvent}
            requestUpdate={updateReminder}
            requestDelete={() => {}}
            loading={isLoading}
            isDisabledChecked
            isDisabledDelete
          />
          <ResponseAlert response={response} />
          <BlockDetail>
            <div className="push-event-header">
              Select taxi companies to add to this airport and click Save.
            </div>
            <InputDetail
              head="Event"
              defaultValue={reminderEvent}
              setField={(value) => {
                setReminderEvent(value);
              }}
              isDisabled={props.detailId !== 'new'}
              isInput
              isNecessarily
              isNumber
            />
            <InputDetail
              head="NameRU"
              defaultValue={reminderNameRu}
              setField={(value) => {
                setReminderNameRu(value);
              }}
              isInput
              isNecessarily
              isDisabled
            />
          </BlockDetail>
          {languageState?.map((val, index) => {
            if (!reminder) return;
            const findCurrentLocal = reminder?.find((el) => el.language === val.code);
            return (
              <BlockDetail key={index}>
                <ModalConfirm
                  variant={COMPONENT_VARIANT.danger}
                  name={'Delete'}
                  header={'Want to delete?'}
                  className={'delete-button'}
                  onClick={() => {
                    requestDelete(findCurrentLocal.id, val.code);
                  }}
                  isDisabled={!findCurrentLocal}>
                  Are you sure you want to delete this item?
                </ModalConfirm>
                <InputDetail
                  head={'Title-' + val.code.toUpperCase()}
                  defaultValue={findCurrentLocal ? findCurrentLocal.title : ''}
                  setField={(value) => {
                    updateLanguageReminder(val.code, value, 'title');
                  }}
                  placeholder={'Push title in ' + FULL_LOCALIZATION_NAME[val.code]}
                  isInput
                  isNecessarily
                  isMultiline
                />
                <InputDetail
                  head={'Text-' + val.code.toUpperCase()}
                  defaultValue={findCurrentLocal ? findCurrentLocal.text : ''}
                  setField={(value) => {
                    updateLanguageReminder(val.code, value, 'text');
                  }}
                  placeholder={'Push text in ' + FULL_LOCALIZATION_NAME[val.code]}
                  isInput
                  isNecessarily
                  isMultiline
                />
              </BlockDetail>
            );
          })}
        </div>
      </div>
    </Loader>
  );
};

export default RemindersDetail;
