import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import './style.scss';
import {
  COMPONENT_VARIANT,
  DESCRIPTION_PAGE,
  EVENT_AIRPORT,
  EVENT_CITY,
  EVENT_COUNTRY,
  MAPS_TYPE_ORDERING,
  UPDATE_DATA_CHANGE
} from '../../../../constants/const';
import {
  getAirport,
  setAirportBus,
  setAirportCategories,
  setAirportCity,
  setAirportCountry,
  setAirportDescription,
  setAirportDescriptionImageFile,
  setAirportFacebook,
  setAirportIataCode,
  setAirportInstagram,
  setAirportLat,
  setAirportLoaded,
  setAirportLon,
  setAirportMaps,
  setAirportMapUrl,
  setAirportMetro,
  setAirportOther,
  setAirportOtherNames,
  setAirportParking,
  setAirportPhone,
  setAirportShuttle,
  setAirportTaxis,
  setAirportTrain,
  setAirportTram,
  setAirportTwitter,
  setAirportWebsite,
  clearAirport,
  setAirportUpdated,
  setAirportLastChecked
} from '../../../../redux/reducers/airport';
import BlockDetail from '../../../DetailComponent/BlockDetail';
import InputDetail from '../../../DetailComponent/InputDetail';
import CardDetail from '../../../DetailComponent/CardDetail';
import CardChoice from '../../../DetailComponent/CardChose';
import LocationDetail from './LocationDetailAirport';
import ResponseAlert from '../../../DetailComponent/ResponseAlert';
import HeaderDetail from '../../../DetailComponent/HeaderDetail';
import BreadcrumbNavigate from '../../../Breadcrumbs';
import Loader from '../../../Loader';
import { MultipartWrapper } from '../../../../services/MultipartWrapper';
import ImageLoadDetail from '../../../ImageComponent';
import MapCategoryBlock from './MapCategoryBlock';
import {
  ROUTE_AIRPORT_ADD_CITY,
  ROUTE_AIRPORT_ADD_COUNTRY,
  ROUTE_AIRPORT_ADD_TAXI,
  ROUTE_CITY,
  ROUTE_COUNTRY,
  ROUTE_TAXIS
} from '../../../../constants/routes';
import { useNavi } from '../../../../services/hooks/useNavi';
import TableChoiceDetail from '../../../DetailComponent/TableChoiceDetail';
import { AirportApi } from '../../../../services/Api/AirportApi';
import { TaxiApi } from '../../../../services/Api/TaxiApi';
import { MapCategoriesApi } from '../../../../services/Api/MapCategoriesApi';
import { CityApi } from '../../../../services/Api/CityApi';
import { CountryApi } from '../../../../services/Api/CountryApi';

const AirportDetail = (props) => {
  const airport = useSelector((state) => state.airport);
  const [categoryLoader, setCategoryLoader] = useState(false);
  const [dataChanged, setDataChanged] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pushRoute } = useNavi();
  useEffect(() => {
    setDataChanged(false);
    dispatch(setAirportLoaded(false));
    if (props.id === 'new') {
      dispatch(clearAirport());
      dispatch(setAirportLoaded(true));
    }
    if ((!airport.id || parseInt(props.id) !== airport.id) && props.id !== 'new')
      dispatch(getAirport(parseInt(props.id)));
    else {
      dispatch(setAirportLoaded(true));
    }
  }, [props.id]);

  const checkFill = (item) => {
    return !item || item === '';
  };

  const checkValidate = () => {
    if (checkFill(airport.otherNames.default)) return false;
    if (checkFill(airport.iataCode)) return false;
    return true;
  };

  const [response, setResponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [validateIata, setValidateIata] = useState(false);
  const [validateCity, setValidateCity] = useState(false);
  const [validateLocation, setValidateLocation] = useState(false);

  const [isUpdateChecked, setIsUpdateChecked] = useState(false);
  const [alertChecked, setAlertChecked] = useState(null);

  const validate = () => {
    setValidateIata(false);
    setValidateCity(false);
    setValidateLocation(false);
    let flag = true;
    if (!airport.iata) {
      setValidateIata(true);
      flag = false;
    }
    if (!airport.city) {
      setValidateCity(true);
      flag = false;
    }
    if (!airport.lon || !airport.lat) {
      setValidateLocation(true);
      flag = false;
    }
    return flag;
  };

  const updateLastChecked = () => {
    setIsUpdateChecked(true);
    AirportApi.updateCheckedAirport(airport.id).then(() => {
      const DataNow = new Date();
      dispatch(setAirportLastChecked(DataNow.toISOString()));
      setIsUpdateChecked(false);
      setAlertChecked('Update last checked success!');
    });
  };

  const updateAirport = () => {
    let multipart = { ...airport };
    const formData = MultipartWrapper.Airport(multipart);
    if (validate() && !isLoading) {
      setIsLoading(true);
      if (!airport.id) {
        AirportApi.createAirport(formData).then((res) => {
          const DataNow = new Date();
          if (res.id) navigate('/' + EVENT_AIRPORT + '/' + res.id);
          setDataChanged(false);
          setResponse(res);
          dispatch(setAirportUpdated(DataNow.toISOString()));
          setIsLoading(false);
        });
      } else {
        AirportApi.updateAirport(airport.id, formData).then(() => {
          const DataNow = new Date();
          setResponse({
            payload: 0,
            status: 'ok'
          });
          dispatch(setAirportUpdated(DataNow.toISOString()));
          setDataChanged(false);
          setIsLoading(false);
        });
        updateLastChecked();
      }
    }
  };

  const addCategory = (name) => {
    setCategoryLoader(true);
    MapCategoriesApi.createMapCategory(name, airport.id).then((res) => {
      if (res.id) {
        let tmp = [...airport.categories];
        tmp.push(res);
        dispatch(setAirportCategories(tmp));
      }
      setCategoryLoader(false);
    });
  };
  const deleteCategory = (id) => {
    MapCategoriesApi.deleteMapCategory(id).then(() => {
      let tmp = [...airport.categories].filter((i) => i.id !== id);
      dispatch(setAirportCategories(tmp));
    });
  };

  const addMap = (name, image, categoryId) => {
    const filterMapsCategory = airport.maps.filter((i) => i.category === categoryId);
    const data = MultipartWrapper.AirportMap({
      name: name,
      imageFile: image,
      category: categoryId,
      ordering:
        filterMapsCategory.length > 0
          ? filterMapsCategory[filterMapsCategory.length - 1].ordering + 1 ?? 1
          : 1
    });
    AirportApi.createAirportMap(data).then((res) => {
      if (res.id) {
        let tmp = [...airport.maps];
        tmp.push(res);
        dispatch(setAirportMaps(tmp));
      }
    });
  };

  const updateOrderingMap = (id, type, categoryId) => {
    const filterMapsCategory = airport.maps
      .filter((i) => i.category === categoryId)
      .sort((a, b) => (a.ordering > b.ordering ? 1 : -1));
    for (let index = 0; index < filterMapsCategory.length; index++) {
      const element = filterMapsCategory[index];
      if (element.id === id) {
        if (type === MAPS_TYPE_ORDERING.up) {
          const defElement = filterMapsCategory[index - 1];
          element.ordering--;
          defElement.ordering++;
          const dataElement = MultipartWrapper.AirportMap({ ...element });
          const dataPrevElement = MultipartWrapper.AirportMap({ ...defElement });
          AirportApi.updateAirportMap(element.id, dataElement);
          AirportApi.updateAirportMap(defElement.id, dataPrevElement);
          break;
        } else {
          const defElement = filterMapsCategory[index + 1];
          element.ordering++;
          defElement.ordering--;
          const dataElement = MultipartWrapper.AirportMap({ ...element });
          const dataPrevElement = MultipartWrapper.AirportMap({ ...defElement });
          AirportApi.updateAirportMap(element.id, dataElement);
          AirportApi.updateAirportMap(defElement.id, dataPrevElement);
          break;
        }
      }
    }
    const newMap = [...airport.maps];
    dispatch(setAirportMaps(newMap));
  };

  const deleteMap = (id) => {
    AirportApi.deleteAirportMap(id).then(() => {
      let tmp = [...airport.maps].filter((i) => i.id !== id);
      dispatch(setAirportMaps(tmp));
    });
  };

  const saveOrderTaxi = (element) => {
    let multipart = { ...element };
    const formData = MultipartWrapper.Taxis(multipart);
    TaxiApi.updateTaxi(element.id, formData).then((res) => {
      console.log('order update success by taxi: ' + res.id + ' new order: ' + res.order);
    });
  };

  return (
    <Loader loading={!airport.loaded} variant={COMPONENT_VARIANT.dark} withMargin={100}>
      <div className="airport-detail">
        {!props.isModal && (
          <BlockDetail>
            <BreadcrumbNavigate name={airport.iata} />
          </BlockDetail>
        )}
        <div className="airport-detail-context">
          <HeaderDetail
            name={airport.iata}
            isValidate={checkValidate()}
            updated={airport.updated}
            lastChecked={airport.lastChecked}
            responsible={airport.responsible}
            requestUpdateChecked={updateLastChecked}
            requestUpdate={updateAirport}
            requestDelete={() => {
              AirportApi.deleteAirport(airport.id);
            }}
            loading={isLoading}
            isUpdateChecked={isUpdateChecked}
            alert={alertChecked}
            isDisabledDelete
          />
          <ResponseAlert response={response} />
          <BlockDetail>
            <div className="item-data">
              <div className="airport-data">
                <div className="airport-left-context">
                  <InputDetail
                    head="Name"
                    placeholder={'Sabiha Gökçen International Airport'}
                    defaultValue={airport.names}
                    setField={(value) => {
                      setDataChanged(true);
                      dispatch(setAirportOtherNames(value));
                    }}
                    isNecessarily
                    isLanguage
                    isInput
                  />
                  <InputDetail
                    head="IATA"
                    placeholder={'SAW'}
                    validate={validateIata}
                    defaultValue={airport.iata}
                    setField={(value) => {
                      setDataChanged(true);
                      dispatch(setAirportIataCode(value));
                    }}
                    isNecessarily
                    isInput
                  />
                  <InputDetail
                    head="Phone number"
                    placeholder={'+902165888888'}
                    defaultValue={airport.phone}
                    setField={(value) => {
                      setDataChanged(true);
                      dispatch(setAirportPhone(value));
                    }}
                    isInput
                  />
                  <InputDetail
                    head="Website"
                    placeholder={'sabihagokcen.aero'}
                    defaultValue={airport.website}
                    setField={(value) => {
                      setDataChanged(true);
                      dispatch(setAirportWebsite(value));
                    }}
                    isInput
                    isWebsite
                  />
                </div>
                <div className="airport-image-context">
                  <ImageLoadDetail
                    header={'Airport photo'}
                    defaultImage={airport.descriptionImageUrl}
                    imageDetail={airport.descriptionImageFile}
                    setImageDetail={(value) => {
                      setDataChanged(true);
                      dispatch(setAirportDescriptionImageFile(value));
                    }}
                    isNecessarily
                    isUnsplash
                  />
                </div>
              </div>
              <InputDetail
                head="Description"
                placeholder={
                  'Sabiha Gökçen International Airport is one of the two international airports serving Istanbul, Turkey. '
                }
                defaultValue={airport.description}
                setField={(value) => {
                  setDataChanged(true);
                  dispatch(setAirportDescription(value));
                }}
                isLanguage
                isMultiline
                isInput
              />
            </div>
            <LocationDetail
              name={airport.names?.en}
              validate={validateLocation}
              lat={airport.lat}
              lon={airport.lon}
              id={airport.id}
              timezone={airport.timezone}
              setLat={(value) => {
                setDataChanged(true);
                dispatch(setAirportLat(value));
              }}
              setLon={(value) => {
                setDataChanged(true);
                dispatch(setAirportLon(value));
              }}
            />
          </BlockDetail>
          <BlockDetail>
            <div className="header">Airport maps</div>
            {airport.id ? (
              <MapCategoryBlock
                categories={airport.categories}
                maps={airport.maps}
                addCategory={addCategory}
                deleteCategory={deleteCategory}
                updateOrderingMap={updateOrderingMap}
                addMap={addMap}
                deleteMap={deleteMap}
                categoryLoader={categoryLoader}
              />
            ) : (
              'Save airport first'
            )}
            <InputDetail
              head="Interactive maps"
              defaultValue={airport.mapUrl}
              setField={(value) => {
                setDataChanged(true);
                dispatch(setAirportMapUrl(value));
              }}
              isWebsite
              isInput
            />
          </BlockDetail>
          <BlockDetail>
            <div className="header-cards">City</div>
            {airport.city ? (
              <CardDetail
                headerPhoto={airport.city.smallPhoto}
                title={airport.city.name}
                text={airport.city.cityCode}
                onClickDetail={() => {
                  pushRoute(
                    ROUTE_CITY + '/' + airport.city.id,
                    airport.city.id,
                    airport.city.cityCode,
                    dataChanged
                  );
                }}
                removeField={() => {
                  dispatch(setAirportCity(null));
                }}
              />
            ) : (
              <div className={classNames({ 'incorrect-border': validateCity })}>
                <CardChoice
                  name={'Add City'}
                  event={EVENT_CITY}
                  request={CityApi.getCityPage}
                  setValue={(value) => {
                    dispatch(setAirportCity(value));
                  }}
                  onNavigate={() => {
                    pushRoute(ROUTE_AIRPORT_ADD_CITY + '/' + airport.id, airport.id, 'Add city');
                  }}
                  isNavigate
                />
              </div>
            )}
          </BlockDetail>

          <BlockDetail>
            <div className="header-cards">Country</div>
            {airport.country ? (
              <CardDetail
                title={airport.country.name}
                text={airport.country.countryCode}
                currencyId={airport.country?.currency}
                onClickDetail={() => {
                  pushRoute(
                    ROUTE_COUNTRY + '/' + airport.country.id,
                    airport.country.id,
                    airport.country.countryCode,
                    dataChanged
                  );
                }}
                removeField={() => {
                  dispatch(setAirportCountry(null));
                }}
              />
            ) : (
              <CardChoice
                name={'Add country'}
                event={EVENT_COUNTRY}
                request={CountryApi.getCountryPage}
                setValue={(value) => dispatch(setAirportCountry(value))}
                onNavigate={() => {
                  pushRoute(
                    ROUTE_AIRPORT_ADD_COUNTRY + '/' + airport.id,
                    airport.id,
                    'Add country'
                  );
                }}
                isNavigate
              />
            )}
          </BlockDetail>
          <BlockDetail>
            <div className="header">Public transport</div>
            <InputDetail
              head="Train"
              defaultValue={airport.train}
              setField={(value) => {
                setDataChanged(true);
                dispatch(setAirportTrain(value));
              }}
              isWebsite
              isInput
            />
            <InputDetail
              head="Metro"
              defaultValue={airport.metro}
              setField={(value) => {
                setDataChanged(true);
                dispatch(setAirportMetro(value));
              }}
              isWebsite
              isInput
            />
            <InputDetail
              head="Bus"
              defaultValue={airport.bus}
              setField={(value) => {
                setDataChanged(true);
                dispatch(setAirportBus(value));
              }}
              isWebsite
              isInput
            />
            <InputDetail
              head="Shuttle"
              defaultValue={airport.shuttle}
              setField={(value) => {
                setDataChanged(true);
                dispatch(setAirportShuttle(value));
              }}
              isWebsite
              isInput
            />
            <InputDetail
              head="Tram"
              defaultValue={airport.tram}
              setField={(value) => {
                setDataChanged(true);
                dispatch(setAirportTram(value));
              }}
              isWebsite
              isInput
            />
            <InputDetail
              head="Other"
              defaultValue={airport.other}
              setField={(value) => {
                setDataChanged(true);
                dispatch(setAirportOther(value));
              }}
              isWebsite
              isInput
            />
            <InputDetail
              head="Parking"
              defaultValue={airport.parking}
              setField={(value) => {
                setDataChanged(true);
                dispatch(setAirportParking(value));
              }}
              isWebsite
              isInput
            />
          </BlockDetail>

          <BlockDetail>
            <TableChoiceDetail
              name={DESCRIPTION_PAGE.taxis}
              buttonContext={'Add Taxi'}
              noChangeDescription={'No taxi companies added to this airport.'}
              title={'title'}
              detail={airport.taxis}
              onChangeData={(value, type) => {
                let newValue = [];
                if (type === UPDATE_DATA_CHANGE.delete) {
                  airport.taxis.forEach((element) => {
                    if (element.id !== value.id) newValue.push(element);
                  });
                }
                if (type === UPDATE_DATA_CHANGE.update) {
                  newValue = airport.taxis;
                  newValue.push(value);
                }
                dispatch(setAirportTaxis(newValue));
              }}
              onChangeOrdering={(id, type) => {
                const newTaxi = [...airport.taxis];
                for (let index = 0; index < newTaxi.length; index++) {
                  const element = newTaxi[index];
                  if (id === element.id) {
                    const order = element.order;
                    let swapElementId = null;
                    if (type === MAPS_TYPE_ORDERING.up) {
                      element.order = newTaxi[index - 1].order;
                      newTaxi[index - 1].order = order;
                      swapElementId = newTaxi[index - 1];
                    } else {
                      element.order = newTaxi[index + 1].order;
                      newTaxi[index + 1].order = order;
                      swapElementId = newTaxi[index + 1];
                    }
                    saveOrderTaxi(element);
                    saveOrderTaxi(swapElementId);
                    break;
                  }
                }
                dispatch(setAirportTaxis(newTaxi.sort((a, b) => (a.order > b.order ? 1 : -1))));
              }}
              onNavigate={() => {
                pushRoute(ROUTE_AIRPORT_ADD_TAXI + '/' + airport.id, airport.id, 'Add taxi');
              }}
              onClickDetail={(taxi) => {
                pushRoute(ROUTE_TAXIS + '/' + taxi.id, taxi.id, taxi.title, dataChanged);
              }}
              isSelextPosition
            />
          </BlockDetail>

          <BlockDetail>
            <div className="header">Social Networks</div>
            <InputDetail
              head="Instagram"
              placeholder={'Instagram link'}
              defaultValue={airport.instagram}
              setField={(value) => {
                setDataChanged(true);
                dispatch(setAirportInstagram(value));
              }}
              isWebsite
              isInput
            />
            <InputDetail
              head="X (Twitter)"
              placeholder={'X (Twitter) link'}
              defaultValue={airport.twitter}
              setField={(value) => {
                setDataChanged(true);
                dispatch(setAirportTwitter(value));
              }}
              isWebsite
              isInput
            />
            <InputDetail
              head="Facebook"
              placeholder={'Facebook link'}
              defaultValue={airport.facebook}
              setField={(value) => {
                setDataChanged(true);
                dispatch(setAirportFacebook(value));
              }}
              isWebsite
              isInput
            />
          </BlockDetail>
        </div>
      </div>
    </Loader>
  );
};

export default AirportDetail;
