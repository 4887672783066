import { instance } from "../AuthApi";

export const ReminderApi = {  
  getReminder() {
    return instance.get('admin/reminder').then((res) => res.data);
  },

  getReminderId(id) {
    return instance.get('admin/reminder/' + id).then((res) => res.data);
  },
  updateReminderId(id, data) {
    return instance
      .put('admin/reminder/' + id, data)
      .then((res) => {
        return res.data;
      })
      .catch((e) => e);
  },
  createReminder(data) {
    return instance
      .post('admin/reminder', data)
      .then((res) => {
        return res.data;
      })
      .catch((e) => e);
  },
  deleteReminder(id) {
    return instance
      .delete('admin/reminder/' + id)
      .then((res) => {
        return res.data;
      })
      .catch((e) => e);
  }
};
