import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import './style.scss';
import {
  clearRental,
  getRental,
  getRentalAirport,
  setRentalAddAirport,
  setRentalAirport,
  setRentalAppStoreUrl,
  setRentalGoogleStoreUrl,
  setRentalLoaded,
  setRentalOrder,
  setRentalRemoveAirport,
  setRentalSquareImage,
  setRentalSquareImageFile,
  setRentalTitle,
  setRentalUrlSchema,
  setRentalWebUrl
} from '../../../../redux/reducers/rental';
import { MultipartWrapper } from '../../../../services/MultipartWrapper';
import Loader from '../../../Loader';
import BlockDetail from '../../../DetailComponent/BlockDetail';
import BreadcrumbNavigate from '../../../Breadcrumbs';
import HeaderDetail from '../../../DetailComponent/HeaderDetail';
import {
  COMPONENT_VARIANT,
  DESCRIPTION_PAGE,
  EVENT_RENTAL,
  UPDATE_DATA_CHANGE
} from '../../../../constants/const';
import InputDetail from '../../../DetailComponent/InputDetail';
import ImageLoadDetail from '../../../ImageComponent';
import ResponseAlert from '../../../DetailComponent/ResponseAlert';
import { useNavi } from '../../../../services/hooks/useNavi';
import TableChoiceDetail from '../../../DetailComponent/TableChoiceDetail';
import { ROUTE_AIRPORT, ROUTE_RENTAL_ADD_AIRPORT } from '../../../../constants/routes';
import { RentalApi } from '../../../../services/Api/RentalApi';

const RentalDetail = (props) => {
  const rental = useSelector((state) => state.rental);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pushRoute } = useNavi();
  const [dataChanged, setDataChanged] = useState(false);

  useEffect(() => {
    setDataChanged(false);
    dispatch(setRentalLoaded(false));
    if (props.detailId === 'new') {
      dispatch(clearRental());
      dispatch(setRentalLoaded(true));
    }
    if ((!rental.id || parseInt(props.detailId) !== rental.id) && props.detailId !== 'new') {
      dispatch(getRental(parseInt(props.detailId)));
      if (rental.airport.length === 0) dispatch(getRentalAirport(parseInt(props.detailId)));
    } else {
      dispatch(setRentalLoaded(true));
    }
  }, [props.detailId]);

  const checkFill = (item) => {
    return !item || item === '';
  };

  const checkValidate = () => {
    if (checkFill(rental.title)) return false;
    return true;
  };

  const [response, setResponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [, setValidateCode] = useState(false);
  const validate = () => {
    setValidateCode(false);

    let flag = true;
    if (!rental.title) {
      setValidateCode(true);
      flag = false;
    }
    return flag;
  };

  const updateRental = () => {
    let multipart = { ...rental };
    const formData = MultipartWrapper.Rental(multipart);
    if (validate() && !isLoading) {
      setIsLoading(true);
      if (!rental.id)
        RentalApi.createRental(formData).then((res) => {
          if (res.id) navigate('/' + EVENT_RENTAL + '/' + res.id);
          setDataChanged(false);
          setResponse(res);
          setIsLoading(false);
        });
      else {
        RentalApi.updateRental(rental.id, formData).then((res) => {
          setDataChanged(false);
          setResponse(res);
          setIsLoading(false);
        });
      }
    }
    if (rental.addAirport.length > 0) {
      RentalApi.updateRentalAirportId(rental.id, getIdAirport(rental.addAirport));
    }
    if (rental.removeAirport.length > 0) {
      RentalApi.deleteRentalAirportId(rental.id, getIdAirport(rental.removeAirport));
    }
    dispatch(setRentalAirport(getFullMassAirportRental()));
    dispatch(setRentalAddAirport([]));
    dispatch(setRentalRemoveAirport([]));
  };

  const getIdAirport = (masAirport) => {
    return masAirport.map((value) => value.id);
  };

  const getFullMassAirportRental = () => {
    const returnAirport = [...rental.addAirport];
    rental.airport.map((value) => {
      const findRemoveAirport = rental.removeAirport.find((el) => el.id === value.id);
      if (!findRemoveAirport) returnAirport.push(value);
    });
    return returnAirport;
  };

  const checkAddAirport = (selectedElement, type) => {
    const findAirport = rental.airport.find((value) => value.id === selectedElement.id);
    if (type === UPDATE_DATA_CHANGE.update) {
      if (!findAirport) {
        const newAddAirport = [...rental.addAirport];
        newAddAirport.push(selectedElement);
        dispatch(setRentalAddAirport(newAddAirport));
      }
    } else {
      const findRemoveAdd = rental.addAirport.find((value) => value.id === selectedElement.id);
      if (findRemoveAdd) {
        const newAddAirport = rental.addAirport.filter((value) => value.id !== selectedElement.id);
        dispatch(setRentalAddAirport(newAddAirport));
      }
      if (findAirport) {
        const newMassRemove = [...rental.removeAirport];
        newMassRemove.push(selectedElement);
        dispatch(setRentalRemoveAirport(newMassRemove));
      }
    }
  };

  return (
    <Loader
      loading={!rental.loaded}
      width={'80px'}
      height={'80px'}
      borderWidth={'10px'}
      variant={COMPONENT_VARIANT.dark}>
      <div className="rental-detail">
        {!props.isModal && (
          <BlockDetail>
            <BreadcrumbNavigate name={rental.title} />
          </BlockDetail>
        )}
        <div className="rental-detail-content">
          <HeaderDetail
            name={rental.title}
            isValidate={checkValidate()}
            requestUpdate={updateRental}
            requestDelete={() => {
              RentalApi.deleteRental(rental.id);
            }}
            loading={isLoading}
          />
          <ResponseAlert response={response} />
          <BlockDetail>
            <div className="rental-detail-data-context">
              <div className="rental-detail-data">
                <InputDetail
                  head="Name"
                  placeholder={'Name'}
                  defaultValue={rental.title}
                  setField={(value) => {
                    setDataChanged(true);
                    dispatch(setRentalTitle(value));
                  }}
                  isNecessarily
                  isInput
                />

                <InputDetail
                  head="Order"
                  placeholder={'Order'}
                  defaultValue={rental.order}
                  setField={(value) => {
                    setDataChanged(true);
                    dispatch(setRentalOrder(value));
                  }}
                  isDisabled
                  isNecessarily
                  isInput
                />

                <InputDetail
                  head="WebURL"
                  placeholder={'webURL'}
                  defaultValue={rental.webUrl}
                  setField={(value) => {
                    setDataChanged(true);
                    dispatch(setRentalWebUrl(value));
                  }}
                  isWebsite
                  isInput
                />

                <InputDetail
                  head="AppStoreURL"
                  placeholder={'webUrl'}
                  defaultValue={rental.appStoreUrl}
                  setField={(value) => {
                    setDataChanged(true);
                    dispatch(setRentalAppStoreUrl(value));
                  }}
                  isWebsite
                  isInput
                />

                <InputDetail
                  head="GoogleStoreURL"
                  placeholder={'GoogleStoreURL'}
                  defaultValue={rental.googleStoreUrl}
                  setField={(value) => {
                    setDataChanged(true);
                    dispatch(setRentalGoogleStoreUrl(value));
                  }}
                  isWebsite
                  isInput
                />

                <InputDetail
                  head="URLSchema"
                  placeholder={'URLSchema'}
                  defaultValue={rental.urlSchema}
                  setField={(value) => {
                    setDataChanged(true);
                    dispatch(setRentalUrlSchema(value));
                  }}
                  isNecessarily
                  isInput
                />
              </div>
              <div className="rental-detail-data">
                <ImageLoadDetail
                  header={'Rental photo'}
                  defaultImage={rental.squareImage}
                  imageDetail={rental.squareImageFile}
                  setImageDetail={(value) => {
                    setDataChanged(true);
                    if (typeof value === 'object') dispatch(setRentalSquareImageFile(value));
                    else {
                      dispatch(setRentalSquareImage(value));
                      dispatch(setRentalSquareImageFile(null));
                    }
                  }}
                  isNecessarily
                  isUnsplash
                />
              </div>
            </div>
          </BlockDetail>
          <BlockDetail>
            <TableChoiceDetail
              name={DESCRIPTION_PAGE.airport}
              description={'Linked Airports'}
              buttonContext={'Add Airport'}
              noChangeDescription={
                rental.id
                  ? 'No selected airport'
                  : 'To add airports, you need to save the rentals service'
              }
              title={'otherNames'}
              detail={getFullMassAirportRental()}
              onChangeData={(value, type) => {
                checkAddAirport(value, type);
              }}
              onNavigate={() => {
                pushRoute(ROUTE_RENTAL_ADD_AIRPORT + '/' + rental.id, rental.id, 'Add airport');
              }}
              onClickDetail={(value) => {
                pushRoute(ROUTE_AIRPORT + '/' + value.id, value.id, value.iataCode, dataChanged);
              }}
              isLocalization
            />
          </BlockDetail>
        </div>
      </div>
    </Loader>
  );
};

export default RentalDetail;
