export const FIELD_TYPE_CARD = {
  header: 'header',
  title: 'title',
  description: 'description',
  lastChecked: 'lastChecked'
};
export const FIELD_INFO_CARD = {
  airports: (value, detail) => {
    if (detail === FIELD_TYPE_CARD.header) return value.photo;
    else if (detail === FIELD_TYPE_CARD.title) return value.iata;
    else if (detail === FIELD_TYPE_CARD.description) return value.name;
    else if (detail === FIELD_TYPE_CARD.lastChecked) return value.checked;
    return null;
  },
  airport_map: (value, detail) => {
    if (detail === FIELD_TYPE_CARD.header) return value.smallImage;
    else if (detail === FIELD_TYPE_CARD.title) return value.name;
    else if (detail === FIELD_TYPE_CARD.description) return value.airport?.iataCode;
    return null;
  },
  airlines: (value, detail) => {
    if (detail === FIELD_TYPE_CARD.header) return value.squareImage;
    else if (detail === FIELD_TYPE_CARD.title) return value.iata;
    else if (detail === FIELD_TYPE_CARD.description) return value.name;
    return null;
  },
  city: (value, detail) => {
    if (detail === FIELD_TYPE_CARD.title) return value.cityCode;
    if (detail === FIELD_TYPE_CARD.description)
      return value.otherNames?.default ?? value.otherNames?.en;
    return null;
  },
  country: (value, detail) => {
    if (detail === FIELD_TYPE_CARD.title) return value.countryCode;
    if (detail === FIELD_TYPE_CARD.description) return value.otherNames?.default ?? value.cityCode;
    return null;
  },
  currency: (value, detail) => {
    if (detail === FIELD_TYPE_CARD.title) return value.currency;
    if (detail === FIELD_TYPE_CARD.description) return value.price;
    return null;
  },
  taxis: (value, detail) => {
    if (detail === FIELD_TYPE_CARD.title) return value.title;
    else if (detail === FIELD_TYPE_CARD.description) return value.webUrl;
    return null;
  },
  rental: (value, detail) => {
    if (detail === FIELD_TYPE_CARD.title) return value.title;
    else if (detail === FIELD_TYPE_CARD.description) return value.order;
    return null;
  },
  public_transport: (value, detail) => {
    if (detail === FIELD_TYPE_CARD.title) return value.name;
    else if (detail === FIELD_TYPE_CARD.description) return value.subName;
    return null;
  }
};
