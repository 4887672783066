import { instance } from "../AuthApi";

export const CurrencyApi = { 
  getCurrencyPage(page, count, search = null, filter = null, sort = null, filterBoolean = null) {
    const params = {
      ...filter,
      page: page,
      search: search ?? '',
      filter: filterBoolean ?? '',
      ordering: sort ?? ''
    };
    return instance.get('admin/currency', { params: params }).then((res) => res.data);
  },
  getCurrencyId(id) {
    return instance.get('admin/currency/' + id).then((res) => res.data);
  },
  createCurrency(data) {
    return instance
      .post('admin/currency', data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then((res) => res.data);
  },
  updateCurrency(id, data) {
    return instance.put('admin/currency/' + id, { data: data }).then((res) => res.data);
  },
  deleteCurrency(id) {
    return instance.delete('admin/currency/' + id).then((res) => res.data);
  }
};
