import { instance } from '../AuthApi';

export const PublicTransportApi = {
  getPublicTransportPage(
    page,
    count,
    search = null,
    filter = null,
    sort = null,
    filterBoolean = null
  ) {
    const params = {
      page: page,
      query: search ?? '',
      airportId: filter.airport,
      filter: filterBoolean ?? '',
      ordering: sort ?? ''
    };
    return instance
      .get('transport', {
        params: params 
      })
      .then((res) => {
        return { results: res.data.payload.transports, count: res.data.payload.count };
      });
  },
  getPublicTransportId(id) {
    return instance.get('transport/' + id).then((res) => {
      return res.data.payload;
    });
  },
  createPublicTransport(data) {
    return instance
      .post('transport', data)
      .then((res) => {
        return res.data;
      })
      .catch((e) => e);
  },
  updatePublicTransport(id, data) {
    return instance
      .put('transport/' + id, data)
      .then((res) => {
        return res.data;
      })
      .catch((e) => e);
  },
  deletePublicTransport(id) {
    return instance.delete('admin/transports/' + id).then((res) => res.data);
  },
  getPublicTransportDescriptionImage() {
    return instance
      .get('admin/transport/image')
      .then((res) => {
        return res.data;
      })
      .catch((res) => {
        console.log(res);
      });
  },
  loadPublicTransportDescriptionImage(image) {
    const data = new FormData();
    data.append('imagePath', image);

    return instance
      .post('admin/transport/image', data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then((res) => res.data)
      .catch((e) => e);
  }
};
