import { instance } from '../AuthApi';

export const AirportApi = {
  getAirportPage(page, count, search = null, filter = null, sort = null, filterBoolean = null) {
    const params = {
      ...filter,
      page: page,
      search: search ?? '',
      filter: filterBoolean ?? '',
      ordering: sort ?? ''
    };
    return instance
      .get('airport', {
        params: params
      })
      .then((res) => {
        return { results: res.data.payload.airports, count: res.data.payload.count };
      });
  },

  getAirportId(id) {
    return instance.get('airport/' + id).then((res) => res.data.payload);
  },

  createAirport(data) {
    return instance
      .post('airport', data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then((res) => {
        return res.data.payload;
      })
      .catch((e) => e);
  },

  updateAirport(id, data) {
    return instance
      .put(
        'airport/' + id,
        data /*{
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }*/
      )
      .then((res) => {
        return res.data.payload;
      })
      .catch((e) => e);
  },

  updateCheckedAirport(id) {
    return instance.put('airport/' + id + '/checked').then((res) => res.data.payload);
  },

  deleteAirport(id) {
    return instance.delete('airport/' + id + '/').then((res) => res.data.payload);
  }
};
