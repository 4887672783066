import { AirportMapApi } from '../../services/Api/AirportMapApi';
import { airportMapsInitialState } from './initialStates';

const CLEAR_AIRPORT_MAP = 'AIRPORT_MAP/CLEAR';
const SET_AIRPORT_MAP_LOADED = 'AIRPORT_MAP/SET_LOADED';
const SET_AIRPORT_MAP_ALL_FIELD = 'AIRPORT_MAP/SET_ALL_FIELD';
const SET_AIRPORT_MAP_ID = 'AIRPORT_MAP/SET_ID';
const SET_AIRPORT_MAP_NAME = 'AIRPORT_MAP/SET_NAME';
const SET_AIRPORT_MAP_IMAGE = 'AIRPORT_MAP/SET_IMAGE';
const SET_AIRPORT_MAP_IMAGE_FILE = 'AIRPORT_MAP/SET_IMAGE_FILE';
const SET_AIRPORT_MAP_TYPE = 'AIRPORT_MAP/SET_TYPE';
const SET_AIRPORT_MAP_CATEGORY = 'AIRPORT_MAP/SET_CATEGORY';

const initialState = airportMapsInitialState;

export const getAirportMap = (id) => {
  return (dispatch) =>
    AirportMapApi.getAirportMapId(id).then((res) => {
      const newData = { ...res, loaded: true, imageFile: null };
      dispatch(setAirportMapAllField(newData));
    });
};
export const clearAirportMap = () => {
  return { type: CLEAR_AIRPORT_MAP };
};
export const setAirportMapLoaded = (condition) => {
  return { type: SET_AIRPORT_MAP_LOADED, payload: condition };
};
export const setAirportMapAllField = (data) => {
  return { type: SET_AIRPORT_MAP_ALL_FIELD, payload: data };
};
export const setAirportMapId = (id) => {
  return { type: SET_AIRPORT_MAP_ID, payload: id };
};
export const setAirportMapName = (name) => {
  return { type: SET_AIRPORT_MAP_NAME, payload: name };
};
export const setAirportMapImage = (image) => {
  return { type: SET_AIRPORT_MAP_IMAGE, payload: image };
};
export const setAirportMapImageFile = (image) => {
  return { type: SET_AIRPORT_MAP_IMAGE_FILE, payload: image };
};
export const setAirportMapType = (mapType) => {
  return { type: SET_AIRPORT_MAP_TYPE, payload: mapType };
};
export const setAirportMapCategory = (category) => {
  return { type: SET_AIRPORT_MAP_CATEGORY, payload: category };
};

const airportMaps = (state = initialState, action) => {
  switch (action.type) {
    case SET_AIRPORT_MAP_LOADED:
      return { ...state, loaded: action.payload };
    case SET_AIRPORT_MAP_ALL_FIELD:
      return { ...state, ...action.payload };
    case SET_AIRPORT_MAP_ID:
      return { ...state, id: action.payload };
    case SET_AIRPORT_MAP_NAME:
      return { ...state, name: action.payload };
    case SET_AIRPORT_MAP_IMAGE:
      return { ...state, image: action.payload };
    case SET_AIRPORT_MAP_IMAGE_FILE:
      return { ...state, imageFile: action.payload };
    case SET_AIRPORT_MAP_TYPE:
      return { ...state, mapType: action.payload };
    case SET_AIRPORT_MAP_CATEGORY:
      return { ...state, category: action.payload };
    case CLEAR_AIRPORT_MAP:
      return { ...initialState };
    default:
      return state;
  }
};

export default airportMaps;
