import { instance } from "../AuthApi";

export const MapCategoriesApi = {     
  getMapCategories() {
    return instance.get('admin/category_map').then((res) => res.data);
  },

  createMapCategory(name, airportId) {
    return instance
      .post('admin/category_map', { name: name, airport: airportId })
      .then((res) => res.data);
  },

  deleteMapCategory(id) {
    return instance.delete('admin/category_map/' + id).then((res) => res.data);
  }
};
