import { instance } from "../AuthApi";

export const TipsApi = {
  getAllTips() {
    return instance
      .get('tips')
      .then((res) => {
        return res.data;
      })
      .catch((res) => {
        console.log(res);
      });
  },
  getTipsId(id) {
    return instance.get('tip/' + id).then((res) => res.data);
  },
  approveTipsId(id, status) {
    return instance.post('tip/' + id + '/' + status).then((res) => res.data);
  },
  deleteTips(id) {
    return instance.delete('tip/' + id).then((res) => res.data);
  },
  
  createTips(airportID, data) {
    return instance
      .post('tip/admin/'+airportID, data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then((res) => {
        return res.data;
      })
      .catch((e) => e);
  },
};
