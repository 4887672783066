import './App.scss';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { ROUTE_AIRPORT, ROUTE_AUTHORIZE, routes } from './constants/routes';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './App.scss';
import { getLanguage, getResponsible } from './redux/reducers/app';
import { isAuthorization } from './services/storage';
import { EVENT_AIRPORT, STORAGE_TOKEN } from './constants/const';
import Tabs from './Components/Tabs';

function App() {
  const [language, responsible] = useSelector((state) => [
    state.app.language,
    state.app.responsible
  ]);
  const dispatch = useDispatch();

  useEffect(() => {
    const token = localStorage.getItem(STORAGE_TOKEN);
    if (window.location.pathname !== ROUTE_AUTHORIZE && (token === null || token === '')) {
      window.location.pathname = ROUTE_AUTHORIZE;
      return;
    }
    if (!language && isAuthorization()) dispatch(getLanguage());
    if (!responsible && isAuthorization()) dispatch(getResponsible());
  }, []);

  return (
    <BrowserRouter>
      <div className={'App'}>
        <Tabs activeKey={EVENT_AIRPORT}>
          <Routes>
            {routes.map((i, index) => (
              <Route key={index} path={i.path} element={i.container} />
            ))}
            <Route path="*" element={<Navigate to={ROUTE_AIRPORT} replace />} />
          </Routes>
        </Tabs>
      </div>
    </BrowserRouter>
  );
}

export default App;
