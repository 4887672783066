import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../Loader';
import {
  COMPONENT_VARIANT,
  EVENT_AIRPORT,
  EVENT_RENTAL,
  UPDATE_DATA_CHANGE
} from '../../../../constants/const';
import TableSelectDetail from '../../../DetailComponent/TableSelectDetail';
import { getRental, getRentalAirport, setRentalAddAirport, setRentalRemoveAirport } from '../../../../redux/reducers/rental';
import { AirportApi } from '../../../../services/Api/AirportApi';

const RentalSelectAirport = (props) => {
  const stateElement = useSelector((state) => state.rental);

  const dispatch = useDispatch();

  useEffect(() => {
    if ((!stateElement.id || props.id !== stateElement.id) && props.id !== 'new')
      dispatch(getRental(parseInt(props.id)));
    if (stateElement.airport.length === 0) dispatch(getRentalAirport(parseInt(props.id)));
  }, [props.id]);

  const onSelect = (element) => {
    checkAddAirport(element, UPDATE_DATA_CHANGE.update);
  };

  const onDelete = (element) => {
    checkAddAirport(element, UPDATE_DATA_CHANGE.delete);
  };

  const getFullMassAirport = () => {
    const returnAirport = [...stateElement.addAirport];
    stateElement.airport.map((value) => {
      const findRemoveAirport = stateElement.removeAirport.find((el) => el.id === value.id);
      if (!findRemoveAirport) returnAirport.push(value);
    });
    return returnAirport;
  };

  const checkAddAirport = (selectedElement, type) => {
    const findAirport = stateElement.airport.find(
      (value) => value.id === selectedElement.id
    );
    if (type === UPDATE_DATA_CHANGE.update) {
      const findRemove = stateElement.removeAirport.find(
        (value) => value.id === selectedElement.id
      );
      if (!findAirport) {
        const newAddAirport = [...stateElement.addAirport];
        newAddAirport.push(selectedElement);
        dispatch(setRentalAddAirport(newAddAirport));
      }
      if (findRemove) {
        const newMassRemove = stateElement.removeAirport.filter(
          (value) => value.id !== selectedElement.id
        );
        dispatch(setRentalRemoveAirport(newMassRemove));
      }
    } else {
      const findRemoveAdd = stateElement.addAirport.find(
        (value) => value.id === selectedElement.id
      );
      if (findRemoveAdd) {
        const newAddAirport = stateElement.addAirport.filter(
          (value) => value.id !== selectedElement.id
        );
        dispatch(setRentalAddAirport(newAddAirport));
      }
      if (findAirport) {
        const newMassRemove = [...stateElement.removeAirport];
        newMassRemove.push(selectedElement);
        dispatch(setRentalRemoveAirport(newMassRemove));
      }
    }
  };

  return (
    <Loader loading={!stateElement.loaded} variant={COMPONENT_VARIANT.dark} withMargin={100}>
      <TableSelectDetail
        title={'Airports'}
        description={'Select Airports companies to add to this airport and click Save.'}
        titleCard={'otherNames'}
        saveData={() => {}}
        request={AirportApi.getAirportPage}
        event={EVENT_AIRPORT}
        navigateEvent={EVENT_RENTAL}
        selectedElement={getFullMassAirport()}
        onSelect={onSelect}
        onDelete={onDelete}
        isMultiLanguage
      />
    </Loader>
  );
};

export default RentalSelectAirport;
