import { CityApi } from '../../services/Api/CityApi';
import { cityInitialState } from './initialStates';

const CLEAR_CITY = 'CITY/CLEAR';
const SET_CITY_LOADED = 'CITY/SET_LOADED';
const SET_CITY_ALL_FIELD = 'CITY/SET_ALL_FIELD';
const SET_CITY_ID = 'CITY/SET_ID';
const SET_CITY_CODE = 'CITY/SET_CODE';
const SET_CITY_TIMEZONE = 'CITY/SET_TIMEZONE';
const SET_CITY_HEADER_PHOTO = 'CITY/SET_HEADER_PHOTO';
const SET_CITY_HEADER_PHOTO_FILE = 'CITY/SET_HEADER_PHOTO_FILE';
const SET_CITY_HEADER_PHOTO_AUTHOR_URL = 'CITY/SET_HEADER_PHOTO_AUTHOR_URL';
const SET_CITY_SEARCH_FIELD = 'CITY/SET_SEARCH_FIELD';
const SET_CITY_COUNTRY = 'CITY/SET_COUNTRY';
const SET_CITY_OTHER_NAMES = 'CITY/SET_OTHER_NAMES';

const initialState = cityInitialState;

export const getCity = (id) => {
  return (dispatch) => {
    CityApi.getCityId(id).then((res) => {
      const newData = { ...res, loaded: true };
      dispatch(setCityAllField(newData));
    });
  }
};

export const clearCity = () => {
  return { type: CLEAR_CITY, payload: true };
};
export const setCityLoaded = (condition) => {
  return { type: SET_CITY_LOADED, payload: condition };
};
export const setCityAllField = (data) => {
  return { type: SET_CITY_ALL_FIELD, payload: data };
};
export const setCityId = (id) => {
  return { type: SET_CITY_ID, payload: id };
};
export const setCityCode = (cityCode) => {
  return { type: SET_CITY_CODE, payload: cityCode };
};
export const setCityTimezone = (timezone) => {
  return { type: SET_CITY_TIMEZONE, payload: timezone };
};
export const setCityHeaderPhoto = (headerPhoto) => {
  return { type: SET_CITY_HEADER_PHOTO, payload: headerPhoto };
};
export const setCityHeaderPhotoFile = (headerPhoto) => {
  return { type: SET_CITY_HEADER_PHOTO_FILE, payload: headerPhoto };
};
export const setCityHeaderPhotoAuthorUrl = (headerPhotoAuthorUrl) => {
  return { type: SET_CITY_HEADER_PHOTO_AUTHOR_URL, payload: headerPhotoAuthorUrl };
};
export const setCitySearchField = (search_field) => {
  return { type: SET_CITY_SEARCH_FIELD, payload: search_field };
};
export const setCityCountry = (country) => {
  return { type: SET_CITY_COUNTRY, payload: country };
};
export const setCityOtherNames = (otherNames) => {
  return { type: SET_CITY_OTHER_NAMES, payload: otherNames };
};

const city = (state = initialState, action) => {
  switch (action.type) {
    case SET_CITY_LOADED:
      return { ...state, loaded: action.payload };
    case SET_CITY_ID:
      return { ...state, id: action.payload };
    case SET_CITY_ALL_FIELD:
      return { ...state, ...action.payload };
    case SET_CITY_CODE:
      return { ...state, cityCode: action.payload };
    case SET_CITY_TIMEZONE:
      return { ...state, timezone: action.payload };
    case SET_CITY_HEADER_PHOTO:
      return { ...state, headerPhoto: action.payload };
    case SET_CITY_HEADER_PHOTO_FILE:
      return { ...state, headerPhotoFile: action.payload };
    case SET_CITY_HEADER_PHOTO_AUTHOR_URL:
      return { ...state, headerPhotoAuthorUrl: action.payload };
    case SET_CITY_SEARCH_FIELD:
      return { ...state, search_field: action.payload };
    case SET_CITY_COUNTRY:
      return { ...state, country: action.payload };
    case SET_CITY_OTHER_NAMES:
      return { ...state, otherNames: action.payload };
    case CLEAR_CITY:
      return { ...initialState };
    default:
      return state;
  }
};

export default city;
