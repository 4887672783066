import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Card from 'react-bootstrap/Card';
import Badge from 'react-bootstrap/Badge';
import ImagePlaceholder from '../../assets/image/ImagePlaceholder.jpg';
import './style.scss';
import { FIELD_INFO_CARD, FIELD_TYPE_CARD } from '../../constants/getFieldInfo';
import { useNavi } from '../../services/hooks/useNavi';
import { convertDateTimeFormat, getDelayDay } from '../../services/convertDateTimeFormat';
import classNames from 'classnames';
import { DELAY_TIME_LONG_AGO, DELAY_TIME_RECENTLY } from '../../constants/const';
import { clearState } from '../../services/storage';

const Cards = (props) => {
  const { pushRoute, pushPatch } = useNavi();
  const storageState = useSelector((state) => state);
  const dispatch = useDispatch();
  const getImageContext = (items) => {
    const imageURL = FIELD_INFO_CARD[props.event](items, FIELD_TYPE_CARD.header);
    if (imageURL) {
      const imageType = imageURL.substring(imageURL.length - 3, imageURL.length);
      if (imageType === 'pdf') {
        return <object data={imageURL} type="application/pdf" width="100%" height="100%"></object>;
      } else {
        return (
          <Card.Img
            variant="top"
            className={classNames({ 'card-image': true, 'card-image-fill': props.isImageFill })}
            src={imageURL}
            onError={(e) => {
              if (e.target.src === window.location.origin + ImagePlaceholder) {
                window.__placeholderError__ = true;
              }
              if (!window.__placeholderError__) e.target.src = ImagePlaceholder;
            }}
          />
        );
      }
    } else
      return (
        <Card.Img
          variant="top"
          className={classNames({ 'card-image': true, 'card-image-fill': props.isImageFill })}
          src={ImagePlaceholder}
        />
      );
  };
  return (
    <div className="cards-page">
      {props.items?.map((value, index) => {
        const timeInfo = FIELD_INFO_CARD[props.event](value, FIELD_TYPE_CARD.lastChecked);
        const colorInfo = getDelayDay(timeInfo);
        return (
          <Card
            key={index}
            className="card"
            onClick={() => {
              pushPatch('/' + props.event, null, props.event);
              pushRoute(
                '/' + props.event + '/' + value.id,
                value.id,
                FIELD_INFO_CARD[props.event](value, FIELD_TYPE_CARD.title)
              );
              dispatch(clearState(props.event, value.id, storageState));
            }}>
            {props.isImage && getImageContext(value)}
            {timeInfo && (
              <div
                className={classNames({
                  'card-time-info': true,
                  'card-time-green': colorInfo <= DELAY_TIME_RECENTLY,
                  'card-time-yellow':
                    colorInfo > DELAY_TIME_RECENTLY && colorInfo <= DELAY_TIME_LONG_AGO,
                  'card-time-red': colorInfo > DELAY_TIME_LONG_AGO
                })}>
                Checked on
                <Badge bg="none" className="time-info-desc">
                  {convertDateTimeFormat(timeInfo)}
                </Badge>
              </div>
            )}
            <Card.Body className="card-body">
              <Card.Title className="card-title">
                {FIELD_INFO_CARD[props.event](value, FIELD_TYPE_CARD.title)}
              </Card.Title>
              <Card.Text className="card-text">
                {FIELD_INFO_CARD[props.event](value, FIELD_TYPE_CARD.description)}
              </Card.Text>
            </Card.Body>
          </Card>
        );
      })}
    </div>
  );
};

export default Cards;
