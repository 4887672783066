import axios from 'axios';
import { STORAGE_TOKEN, STORAGE_USER_NAME } from '../constants/const';
import { baseURL } from './baseApiUrl';

export const instance = axios.create({
  baseURL: baseURL,
  headers: {
    Authorization: `Bearer ${localStorage.getItem(STORAGE_TOKEN) ?? ''}`
  }
});

instance.interceptors.response.use(
  function (config) {
    return config;
  },
  function (error) {
    if ([401, 403].includes(error.response.status)) {
      localStorage.clear();
      location.reload();
    }

    return Promise.reject(error);
  }
);

export const downloadFileURl = (url) => {
  return axios.get(url, {
    responseType: 'blob'
  });
};

export const AuthApi = {
  getProfile(login, password) {
    return instance
      .post('auth', {
        login: login,
        password: password
      })
      .then((res) => {
        localStorage.setItem(STORAGE_TOKEN, res.data.payload.token);
        localStorage.setItem(STORAGE_USER_NAME, login);
        instance.defaults.headers.Authorization = `Bearer ${res.data.payload.token}`;
        return res.data;
      });
  },

  getLanguages() {
    return instance.get('languages').then((res) => res.data.payload);
  },

  getResponsible() {
    return instance.get('admins').then((res) => res.data.payload);
  }
};
