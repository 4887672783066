import React, { useEffect, useState } from 'react';
import { COMPONENT_VARIANT, FULL_LOCALIZATION_NAME } from '../../../../constants/const';
import { useSelector } from 'react-redux';
import InputDetail from '../../../DetailComponent/InputDetail';
import BlockDetail from '../../../DetailComponent/BlockDetail';
import ResponseAlert from '../../../DetailComponent/ResponseAlert';
import HeaderDetail from '../../../DetailComponent/HeaderDetail';
import BreadcrumbNavigate from '../../../Breadcrumbs';
import Loader from '../../../Loader';
import './style.scss';
import { MultipartWrapper } from '../../../../services/MultipartWrapper';
import ModalConfirm from '../../../ModalConfirm';
import { MarketingApi } from '../../../../services/Api/MarketingApi';

const MarketingDetail = (props) => {
  const languageState = useSelector((state) => state.app.language);
  const [marketing, setMarketing] = useState(null);
  const [addMarketing, setAddMarketing] = useState([]);

  const [marketingEvent, setMarketingEvent] = useState(null);
  const [marketingNameRu, setMarketingNameRu] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState(null);

  const updateMarketing = () => {
    setIsLoading(true);
    let notUpdated = true;
    marketing.map((value) => {
      if (value.updated) {
        let multipart = { ...value };
        const formData = MultipartWrapper.Marketing(multipart);
        MarketingApi.updateMarketingPushId(value.id, formData).then((res) => {
          setResponse(res);
          setIsLoading(false);
        });
      }
    });
    if (notUpdated) {
      setIsLoading(false);
    }
    if (addMarketing.length > 0) {
      setIsLoading(true);
      addMarketing.map((value) => {
        MarketingApi.createMarketingPushId(value).then((res) => {
          if (res.id) {
            const newMarketing = [...marketing];
            newMarketing.push(res);
            setMarketing(newMarketing);
          }
          setResponse(res);
          setIsLoading(false);
        });
      });
      setAddMarketing([]);
    }
  };
  const requestDelete = (id, language) => {
    setIsLoading(true);
    MarketingApi.deleteMarketingPush(id).then((res) => {
      let newMarketing = [...marketing];
      newMarketing = newMarketing.filter((val) => val.language !== language);
      setResponse(res);
      setIsLoading(false);
      newMarketing.push(res);
      setMarketing(newMarketing);
    });
  };
  useEffect(() => {
    if (!marketing) {
      if (props.detailId === 'new') setMarketing([]);
      else
        MarketingApi.getMarketingPush().then((res) => {
          let resMarketing = res;
          const findMarketingId = resMarketing.find((val) => val.id === parseInt(props.detailId));
          resMarketing = resMarketing.filter((val) => val.event === findMarketingId.event);
          const massMarketing = resMarketing.map((value) => {
            return {
              event: value.event,
              id: value.id,
              language: value.language,
              text: value.text,
              title: value.title,
              type: value.type,
              url: value.url,
              updated: false
            };
          });
          const languageRuMarketing = massMarketing.find((val) => val.language === 'ru');
          setMarketing(massMarketing);
          setMarketingEvent(findMarketingId.event);
          setMarketingNameRu(languageRuMarketing.title);
        });
    }
  }, []);

  const updateLanguageMarketing = (language, value, type) => {
    const findMarketing = marketing.find((val) => val.language === language);
    const findNewMarketing = addMarketing.find((val) => val.language === language);
    if (value === '' && !findMarketing && findNewMarketing) {
      if (
        (findNewMarketing.title === '' && type === 'text') ||
        (findNewMarketing.text === '' && type === 'title')
      ) {
        const newAddMarketing = [...addMarketing];
        newAddMarketing.filter((val) => val.language !== language);
        setAddMarketing(newAddMarketing);
      }
    }
    if (!findMarketing) {
      if (!findNewMarketing) {
        if (value === '') return;
        addMarketing.push({
          event: marketingEvent,
          language: language,
          text: type === 'text' ? value : '',
          title: type === 'title' ? value : '',
          type: 'OpenUrl',
          url: ''
        });
        if (language === 'ru' && type === 'title') setMarketingNameRu(value);
      } else {
        const newAddMarketing = [...addMarketing];
        newAddMarketing.map((val) => {
          if (val.language === language) {
            if (type === 'title') val.title = value;
            else val.text = value;
            if (language === 'ru' && type === 'title') setMarketingNameRu(value);
          }
        });
        setAddMarketing(newAddMarketing);
      }
    } else {
      const newMarketing = [...marketing];
      newMarketing.map((val) => {
        if (val.language === language) {
          if (type === 'title') val.title = value;
          else val.text = value;
          if (language === 'ru' && type === 'title') setMarketingNameRu(value);
          val.updated = true;
        }
      });
      setMarketing(newMarketing);
    }
  };

  return (
    <Loader
      loading={!marketing || !languageState}
      width={'80px'}
      height={'80px'}
      borderWidth={'10px'}
      variant={COMPONENT_VARIANT.dark}>
      <div className="push-event-detail">
        {!props.isModal && (
          <BlockDetail>
            <BreadcrumbNavigate name={marketingEvent} />
          </BlockDetail>
        )}
        <div className="push-event-detail-content">
          <HeaderDetail
            name={marketingEvent}
            requestUpdate={updateMarketing}
            requestDelete={() => {}}
            loading={isLoading}
            isDisabledChecked
            isDisabledDelete
          />
          <ResponseAlert response={response} />
          <BlockDetail>
            <div className="push-event-header">
              Select taxi companies to add to this airport and click Save.
            </div>
            <InputDetail
              head="Event"
              defaultValue={marketingEvent}
              setField={(value) => {
                setMarketingEvent(value);
              }}
              isDisabled={props.detailId !== 'new'}
              isInput
              isNecessarily
            />
            <InputDetail
              head="NameRU"
              defaultValue={marketingNameRu}
              setField={(value) => {
                setMarketingNameRu(value);
              }}
              isInput
              isNecessarily
              isDisabled
            />
          </BlockDetail>
          {languageState?.map((val, index) => {
            if (!marketing) return;
            const findCurrentLocal = marketing?.find((el) => el.language === val.code);
            return (
              <BlockDetail key={index}>
                <ModalConfirm
                  variant={COMPONENT_VARIANT.danger}
                  name={'Delete'}
                  header={'Want to delete?'}
                  className={'delete-button'}
                  onClick={() => {
                    requestDelete(findCurrentLocal.id, val.code);
                  }}
                  isDisabled={!findCurrentLocal}>
                  Are you sure you want to delete this item?
                </ModalConfirm>
                <InputDetail
                  head={'Title-' + val.code.toUpperCase()}
                  defaultValue={findCurrentLocal ? findCurrentLocal.title : ''}
                  setField={(value) => {
                    updateLanguageMarketing(val.code, value, 'title');
                  }}
                  placeholder={'Push title in ' + FULL_LOCALIZATION_NAME[val.code]}
                  isInput
                  isNecessarily
                  isMultiline
                />
                <InputDetail
                  head={'Text-' + val.code.toUpperCase()}
                  defaultValue={findCurrentLocal ? findCurrentLocal.text : ''}
                  setField={(value) => {
                    updateLanguageMarketing(val.code, value, 'text');
                  }}
                  placeholder={'Push text in ' + FULL_LOCALIZATION_NAME[val.code]}
                  isInput
                  isNecessarily
                  isMultiline
                />
              </BlockDetail>
            );
          })}
        </div>
      </div>
    </Loader>
  );
};

export default MarketingDetail;
