import {
  DESCRIPTION_PAGE,
  DIVIDER_ELEMENT,
  EVENT_AIRLINES,
  EVENT_AIRPORT,
  //EVENT_AIRPORT_MAP,
 // EVENT_CITY,
  //EVENT_COUNTRY,
  EVENT_PUBLIC_TRANSPORT,
 // EVENT_PUSH,
 // EVENT_RENTAL,
  EVENT_TAXIS,
  EVENT_TIPS,
 // EVENT_WEATHER_STATE
} from './const';
import Airports from '../Components/svg/Airports';
//import AirportMaps from '../Components/svg/AirportsMaps';
import Taxis from '../Components/svg/Taxis';
import AirportTabs from '../Components/DetailPage/Tab/AirportTabs';
//import AirportMapTabs from '../Components/DetailPage/Tab/AirportMapTabs';
import TaxiTabs from '../Components/DetailPage/Tab/TaxisTabs';
//import PushEventsTabs from '../Components/DetailPage/Tab/PushEventsTabs';
//import Push from '../Components/svg/Push';
import AirlinesTabs from '../Components/DetailPage/Tab/AirlinesTabs';
import Airlines from '../Components/svg/Airlines';
//import RentalTabs from '../Components/DetailPage/Tab/RentalTabs';
//import Rental from '../Components/svg/Rental';
//import CountryTabs from '../Components/DetailPage/Tab/CountryTabs';
//import Country from '../Components/svg/Country';
//import WeatherStateTabs from '../Components/DetailPage/Tab/WeatherStateTabs';
//import Weather from '../Components/svg/Weather';
//import CitiesTabs from '../Components/DetailPage/Tab/CitiesTabs';
//import City from '../Components/svg/City';
import PublicTransportTabs from '../Components/DetailPage/Tab/PublicTransportTabs';
import Transport from '../Components/svg/Transport';
import TipsPage from '../Containers/TipsPage';
import Tips from '../Components/svg/Tips';

export const TAB_CONTROLLER_ELEMENT = [
  {
    title: DESCRIPTION_PAGE.airport,
    eventKey: EVENT_AIRPORT,
    page: <AirportTabs />,
    icon: <Airports />
  },
  /*{
    title: DESCRIPTION_PAGE.airportMap,
    eventKey: EVENT_AIRPORT_MAP,
    page: <AirportMapTabs />,
    icon: <AirportMaps />
  },*/
  {
    title: DESCRIPTION_PAGE.public_transport,
    eventKey: EVENT_PUBLIC_TRANSPORT,
    page: <PublicTransportTabs />,
    icon: <Transport />
  },
  { title: DESCRIPTION_PAGE.taxis, eventKey: EVENT_TAXIS, page: <TaxiTabs />, icon: <Taxis /> },
  /*{
    title: DESCRIPTION_PAGE.rental,
    eventKey: EVENT_RENTAL,
    page: <RentalTabs />,
    icon: <Rental />
  },*/
  {
    title: DESCRIPTION_PAGE.airlines,
    eventKey: EVENT_AIRLINES,
    page: <AirlinesTabs />,
    icon: <Airlines />
  },
  {
    title: null,
    eventKey: DIVIDER_ELEMENT,
    page: null,
    icon: null
  },
  /*{
    title: DESCRIPTION_PAGE.country,
    eventKey: EVENT_COUNTRY,
    page: <CountryTabs />,
    icon: <Country />
  },
  {
    title: DESCRIPTION_PAGE.city,
    eventKey: EVENT_CITY,
    page: <CitiesTabs />,
    icon: <City />
  },
  {
    title: null,
    eventKey: DIVIDER_ELEMENT,
    page: null,
    icon: null
  },*/
  {
    title: DESCRIPTION_PAGE.tips,
    eventKey: EVENT_TIPS,
    page: <TipsPage />,
    icon: <Tips />
  },
  /*{
    title: DESCRIPTION_PAGE.pushEvents,
    eventKey: EVENT_PUSH,
    page: <PushEventsTabs />,
    icon: <Push />
  },
  {
    title: DESCRIPTION_PAGE.weather_state,
    eventKey: EVENT_WEATHER_STATE,
    page: <WeatherStateTabs />,
    icon: <Weather />
  }*/
];
