import { instance } from "../AuthApi";

export const CityApi = {
  getCityPage(page, count, search = null, filter = null, sort = null, filterBoolean = null) {
    const params = {
      ...filter,
      page: page,
      search: search ?? '',
      filter: filterBoolean ?? '',
      ordering: sort ?? ''
    };
    return instance.get('admin/cities', { params: params }).then((res) => res.data);
  },
  getCityId(Id) {
    return instance.get('admin/cities/' + Id).then((res) => res.data);
  },

  createCity(data) {
    return instance
      .post('admin/cities', data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then((res) => res.data)
      .catch((e) => e);
  },
  updateCity(id, data) {
    return instance
      .put('admin/cities/' + id, data)
      .then((res) => res.data)
      .catch((e) => e);
  },

  loadCityImage(id, image) {
    const data = new FormData();
    data.append('cityImage', image);

    return instance
      .post('admin/cities/image/' + id, data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then((res) => res.data)
      .catch((e) => e);
  },

  deleteCity(id) {
    return instance.delete('admin/cities/' + id).then((res) => res.data);
  }
};
