import { instance } from '../AuthApi';

export const TaxiApi = {
  getTaxiPage(page, count, search = null, filter = null, sort = null, filterBoolean = null) {
    const params = {
      ...filter,
      page: page,
      query: search ?? '',
      filter: filterBoolean ?? '',
      ordering: sort ?? ''
    };
    return instance.get('taxi', { params: params }).then((res) => {
      return { results: res.data.payload.taxis, count: res.data.payload.count };
    });
  },

  getTaxiId(id) {
    return instance.get('taxi/' + id).then((res) => res.data.payload);
  },

  getTaxiAirportId(id) {
    return instance.get('airport/taxis/' + id).then((res) => res.data.payload);
  },

  updateTaxiAirportId(id, idAirport) {
    return instance.post('airport/' + idAirport + '/taxi/' + id).then((res) => {
      return res.data;
    });
  },

  deleteTaxiAirportId(id, idAirport) {
    return instance.delete('airport/' + idAirport + '/taxi/' + id).then((res) => {
      return res.data;
    });
  },

  createTaxi(data) {
    return instance
      .post('taxi', data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then((res) => res.data)
      .catch((e) => e);
  },

  updateTaxi(id, data) {
    return instance
      .put('taxi/' + id, data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then((res) => res.data)
      .catch((e) => e);
  },

  deleteTaxi(id) {
    return instance.delete('taxi/' + id).then((res) => res.data);
  }
};
