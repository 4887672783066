import React from 'react';
import PaginationPage from '../../../PaginationPage';
import { DESCRIPTION_PAGE, EVENT_AIRLINES } from '../../../../constants/const';
import { AirlinesApi } from '../../../../services/Api/AirlinesApi';

const AirlinesTabs = () => {
  return (
    <div className="tabs-control">
      <PaginationPage
        name={DESCRIPTION_PAGE.airlines}
        event={EVENT_AIRLINES}
        request={AirlinesApi.getAirlinesPage}
        isImage
        isDetail
        isImageFill
        disabledCreate
      />
    </div>
  );
};

export default AirlinesTabs;
