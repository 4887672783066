import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import './style.scss';
import {
  getCurrency,
  setCurrencyCurrency,
  setCurrencyLoaded,
  setCurrencyPrice,
  setCurrencySymbol
} from '../../../../redux/reducers/currency';
import BlockDetail from '../../../DetailComponent/BlockDetail';
import InputDetail from '../../../DetailComponent/InputDetail';
import HeaderDetail from '../../../DetailComponent/HeaderDetail';
import BreadcrumbNavigate from '../../../Breadcrumbs';
import Loader from '../../../Loader';
import { COMPONENT_VARIANT, EVENT_CURRENCY } from '../../../../constants/const';
import { MultipartWrapper } from '../../../../services/MultipartWrapper';
import { CurrencyApi } from '../../../../services/Api/CurrencyApi';
const CurrencyDetail = (props) => {
  const currency = useSelector((state) => state.currency);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (props.detailId === 'new') {
      dispatch(setCurrencyLoaded(true));
    }
    if (!currency.id && props.detailId !== 'new') dispatch(getCurrency(parseInt(props.detailId)));
    else {
      dispatch(setCurrencyLoaded(true));
    }
  }, []);

  const checkFill = (item) => {
    return !item || item === '';
  };

  const checkValidate = () => {
    if (checkFill(currency.currency)) return false;
    return true;
  };

  return (
    <Loader
      loading={!currency.loaded}
      width={'80px'}
      height={'80px'}
      borderWidth={'10px'}
      variant={COMPONENT_VARIANT.dark}>
      <div className="currency-detail">
        {!props.isModal && (
          <BlockDetail>
            <BreadcrumbNavigate name={currency.currency}/>
          </BlockDetail>
        )}
        <div className="currency-detail-content">
          <HeaderDetail
            name={currency.currency}
            isValidate={checkValidate()}
            requestUpdate={() => {
              let multipart = { ...currency };
              const formData = MultipartWrapper.Currency(multipart);
              if (currency.id === 'new')
                CurrencyApi.createCurrency(formData).then((res) => {
                  navigate('/' + EVENT_CURRENCY + '/' + res.id);
                });
              else CurrencyApi.updateCurrency(currency.id, formData);
            }}
            requestDelete={() => {
              CurrencyApi.deleteCurrency(currency.id);
            }}
          />
          <BlockDetail>
            <InputDetail
              head="Currency"
              defaultValue={currency.currency}
              setField={(value) => {
                dispatch(setCurrencyCurrency(value));
              }}
              isNecessarily
              isInput
            />
            <InputDetail
              head="Price"
              defaultValue={currency.price}
              setField={(value) => {
                dispatch(setCurrencyPrice(value));
              }}
              isNecessarily
              isInput
            />
            <InputDetail
              head="Symbol"
              defaultValue={currency.symbol}
              setField={(value) => {
                dispatch(setCurrencySymbol(value));
              }}
              isNecessarily
              isInput
            />
          </BlockDetail>
        </div>
      </div>
    </Loader>
  );
};

export default CurrencyDetail;
