import { instance } from "../AuthApi";

export const RentalApi = {  
  getRentalPage(page, count, search = null, filter = null, sort = null, filterBoolean = null) {
    const params = {
      ...filter,
      page: page,
      search: search ?? '',
      filter: filterBoolean ?? '',
      ordering: sort ?? ''
    };
    return instance
      .get('admin/rental', {
        params: params
      })
      .then((res) => res.data);
  },

  getRentalId(id) {
    return instance.get('admin/rental/' + id).then((res) => res.data);
  },
  createRental(data) {
    return instance
      .post('admin/rental', data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then((res) => {
        return res.data;
      })
      .catch((e) => e);
  },
  updateRental(id, data) {
    return instance
      .put('admin/rental/' + id, data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then((res) => {
        return res.data;
      })
      .catch((e) => e);
  },
  deleteRental(id) {
    return instance.delete('admin/rental/' + id).then((res) => res.data);
  },
  getRentalAirportId(id) {
    return instance.get('admin/airport/rentals/' + id).then((res) => res.data);
  },
  updateRentalAirportId(id, data) {
    return instance.post('admin/airport/rentals/' + id, { airports: data }).then((res) => {
      return res.data;
    });
  },
  deleteRentalAirportId(id, data) {
    return instance
      .delete('admin/airport/rentals/' + id, { data: { airports: data } })
      .then((res) => {
        return res.data;
      });
  }
};
