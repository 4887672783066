import React from 'react';
import PaginationPage from '../../../PaginationPage';
import { EVENT_RENTAL } from '../../../../constants/const';
import { RentalApi } from '../../../../services/Api/RentalApi';

const RentalTabs = () => {
  return (
    <div className="tabs-control">
      <PaginationPage
        name={'Rental services'}
        event={EVENT_RENTAL}
        request={RentalApi.getRentalPage}
        isDetail
      ></PaginationPage>
    </div>
  );
};

export default RentalTabs;
