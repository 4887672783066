import React, { useEffect, useState } from 'react';
import Loader from '../Loader';
import { COMPONENT_VARIANT, LANGUAGE_DEFAULT, STORAGE_LANGUAGE } from '../../constants/const';

import './style.scss';
import { Dropdown, Table, Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { WeatherStateApi } from '../../services/Api/WeatherStateApi';

const WeatherState = () => {
  const getActiveLanguage = () => {
    const language = localStorage.getItem(STORAGE_LANGUAGE);
    if (!language) {
      localStorage.setItem(STORAGE_LANGUAGE, LANGUAGE_DEFAULT);
      return LANGUAGE_DEFAULT;
    }
    return language;
  };
  const language = useSelector((state) => state.app.language);
  const [weatherState, setWeatherState] = useState(null);
  const [activeLanguage, setActiveLanguage] = useState(getActiveLanguage());
  const [checkEmptyLanguage, setCheckEmptyLanguage] = React.useState(false);

  const [updateLocalization, setUpdateLocalization] = useState([]);
  const [createLocalization, setCreateLocalization] = useState([]);

  useEffect(() => {
    if (weatherState === null)
      WeatherStateApi.getWeatherStatePage().then((res) => {
        setWeatherState(res);
      });
    setActiveLanguage(localStorage.getItem(STORAGE_LANGUAGE));
  }, []);

  const saveLocalization = () => {
    if (updateLocalization.length !== 0) {
      updateLocalization.map((element) => {
        const formData = new FormData();
        formData.append('description', element.description);
        WeatherStateApi.updateWeatherState(element.id, formData).then((res) => {
          console.log(res);
          setUpdateLocalization([]);
        });
      });
    }
    if (createLocalization.length !== 0) {
      createLocalization.map((element) => {
        const formData = new FormData();
        formData.append('language', element.language);
        formData.append('description', element.description);
        formData.append('weather', element.weather);
        WeatherStateApi.createWeatherStateLocalization(formData).then(() => {
          setCreateLocalization([]);
        });
      });
    }
  };

  const onChangeInputUpdateCreate = (element, newValue) => {
    const findLanguages = weatherState
      .find((e) => e.id === element.id)
      .languages.find((e) => e.language === activeLanguage);
    if (findLanguages) {
      const newCreateLocalization = [...createLocalization];
      const findCreateLanguages = newCreateLocalization.find((e) => e.weather === element.id);
      if (findCreateLanguages) {
        findCreateLanguages.description = newValue;
        setCreateLocalization(newCreateLocalization);
      } else {
        const newUpdateLocalization = [...updateLocalization];
        const findUpdateLanguages = newUpdateLocalization.find((e) => e.id === findLanguages.id);
        if (findUpdateLanguages) {
          findUpdateLanguages.description = newValue;
          setUpdateLocalization(newUpdateLocalization);
        } else {
          newUpdateLocalization.push({
            id: findLanguages.id,
            language: findLanguages.language,
            description: newValue
          });
          setUpdateLocalization(newUpdateLocalization);
        }
      }
    } else {
      const newCreateLocalization = [...createLocalization];
      newCreateLocalization.push({
        language: activeLanguage,
        description: newValue,
        weather: element.id
      });
      setCreateLocalization(newCreateLocalization);
    }
  };

  const onChangeWeatherStateShow = (element, newValue) => {
    const newWeatherState = [...weatherState];
    const findWeatherState = newWeatherState.find((value) => value.id === element.id);
    const findWeatherLanguage = findWeatherState.languages.find(
      (value) => value.language === activeLanguage
    );
    if (findWeatherLanguage) findWeatherLanguage.description = newValue;
    else
      findWeatherState.languages.push({
        language: activeLanguage,
        description: newValue,
        weather: element.id
      });
    setWeatherState(newWeatherState);
  };

  const onChangeLanguage = (element, newValue) => {
    onChangeInputUpdateCreate(element, newValue);
    onChangeWeatherStateShow(element, newValue);
  };

  const getWeatherStateLanguage = (value) => {
    const findLanguages = value.languages.find((e) => e.language === activeLanguage);
    if (!findLanguages) return '';
    return findLanguages.description;
  };

  const getWeatherStates = () => {
    if (!checkEmptyLanguage) return weatherState;
    return weatherState.filter((value) => {
      const findLanguages = value.languages.find((e) => e.language === activeLanguage);
      return !findLanguages || findLanguages.description === '';
    });
  };

  return (
    <Loader loading={weatherState === null} variant={COMPONENT_VARIANT.dark} withMargin={100}>
      <div className="weather-page">
        <div className="navbar-info">
          <div className="localization">
            <Dropdown>
              <Dropdown.Toggle variant="outline-primary" className="weather-context">
                {'Localization: ' + activeLanguage}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {language?.map((value, index) => {
                  return (
                    <Dropdown.Item
                      key={index}
                      onClick={() => {
                        setActiveLanguage(value.code);
                      }}
                      disabled={activeLanguage === value.code}>
                      {value.code}
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="empty-localization-only">
            <label>
              <input
                type="checkbox"
                onChange={() => {
                  setCheckEmptyLanguage(!checkEmptyLanguage);
                }}
              />
              Show only empty language
            </label>
          </div>
          <div className="save-button">
            <Button
              variant="primary"
              className="button-delete"
              onClick={() => {
                saveLocalization();
              }}>
              Save
            </Button>
          </div>
        </div>
        <div className="fake-navbar-info"></div>
        <div className="weather-tabs-context">
          {weatherState && (
            <Table striped bordered hover variant="light" className="table">
              <tbody>
                {getWeatherStates()?.map((value, index) => (
                  <tr key={index}>
                    <td className="weather-tabs-id">{value.id}</td>
                    <td className="weather-tabs-name">{value.name}</td>
                    <td className="weather-tabs-description">
                      <input
                        className="weather-language-input"
                        type="text"
                        placeholder="Input localization"
                        value={getWeatherStateLanguage(value)}
                        onChange={(e) => {
                          onChangeLanguage(value, e.target.value);
                        }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </div>
      </div>
    </Loader>
  );
};

export default WeatherState;
