import { CountryApi } from '../../services/Api/CountryApi';
import { countryInitialState } from './initialStates';

const CLEAR_COUNTRY = 'COUNTRY/CLEAR';
const SET_COUNTRY_LOADED = 'COUNTRY/SET_LOADED';
const SET_COUNTRY_ALL_FIELD = 'COUNTRY/SET_ALL_FIELD';
const SET_COUNTRY_ID = 'COUNTRY/SET_ID';
const SET_COUNTRY_CODE = 'COUNTRY/SET_CODE';
const SET_COUNTRY_CURRENCY = 'COUNTRY/SET_CURRENCY';
const SET_COUNTRY_FLAG = 'COUNTRY/SET_FLAG';
const SET_COUNTRY_SEARCH_FIELD = 'COUNTRY/SET_SEARCH_FIELD';
const SET_COUNTRY_OTHER_NAMES = 'COUNTRY/SET_OTHER_NAMES';
const SET_COUNTRY_CURRENCY_INFO = 'COUNTRY/SET_CURRENCY_INFO';

const initialState = countryInitialState

export const getCountry = (id) => {
  return (dispatch) =>
    CountryApi.getCountryId(id).then((res) => {
      const newData = { ...res, loaded: true };     
      dispatch(setCountryAllField(newData));
    });
};
export const clearCountry = () => {
  return { type: CLEAR_COUNTRY };
};
export const setCountryLoaded = (condition) => {
  return { type: SET_COUNTRY_LOADED, payload: condition };
};
export const setCountryAllField = (data) => {
  return { type: SET_COUNTRY_ALL_FIELD, payload: data };
};
export const setCountryId = (id) => {
  return { type: SET_COUNTRY_ID, payload: id };
};
export const setCountryCode = (countryCode) => {
  return { type: SET_COUNTRY_CODE, payload: countryCode };
};
export const setCountryCurrency = (currency) => {
  return { type: SET_COUNTRY_CURRENCY, payload: currency };
};
export const setCountryFlag = (flag) => {
  return { type: SET_COUNTRY_FLAG, payload: flag };
};
export const setCountrySearchField = (search_field) => {
  return { type: SET_COUNTRY_SEARCH_FIELD, payload: search_field };
};
export const setCountryOtherNames = (otherNames) => {
  return { type: SET_COUNTRY_OTHER_NAMES, payload: otherNames };
};
export const setCountryCurrencyInfo = (currencyInfo) => {
  return { type: SET_COUNTRY_CURRENCY_INFO, payload: currencyInfo };
};

const country = (state = initialState, action) => {
  switch (action.type) {
    case SET_COUNTRY_LOADED:
      return { ...state, loaded: action.payload };
    case SET_COUNTRY_ALL_FIELD:
      return { ...state, ...action.payload };
    case SET_COUNTRY_ID:
      return { ...state, id: action.payload };
    case SET_COUNTRY_CODE:
      return { ...state, countryCode: action.payload };
    case SET_COUNTRY_CURRENCY:
      return { ...state, currency: action.payload };
    case SET_COUNTRY_FLAG:
      return { ...state, flag: action.payload };
    case SET_COUNTRY_SEARCH_FIELD:
      return { ...state, search_field: action.payload };
    case SET_COUNTRY_OTHER_NAMES:
      return { ...state, otherNames: action.payload };
    case SET_COUNTRY_CURRENCY_INFO:
      return { ...state, currencyInfo: action.payload };
    case CLEAR_COUNTRY:
      return { ...initialState };
    default:
      return state;
  }
};

export default country;
