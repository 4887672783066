import { instance } from "../AuthApi";

export const AirportMapApi = {     
   getAirportMapPage(page, count, search = null, filter = null, sort = null, filterBoolean = null) {
    const params = {
      ...filter,
      page: page,
      search: search ?? '',
      filter: filterBoolean ?? '',
      ordering: sort ?? ''
    };
    return instance.get('admin/airport_map', { params: params }).then((res) => res.data);
  },
  getAirportMapId(id) {
    return instance.get('admin/airport_map/' + id).then((res) => res.data);
  },

  createAirportMap(data) {
    return instance
      .post('admin/airport_map', data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then((res) => {
        return res.data;
      })
      .catch((e) => e);
  },

  updateAirportMap(id, data) {
    return instance
      .put('admin/airport_map/' + id, data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then((res) => {
        return res.data;
      })
      .catch((e) => e);
  },

  deleteAirportMap(id) {
    return instance.delete('admin/airport_map/' + id).then((res) => res.data);
  }
};
