import React, { useEffect, useRef, useState } from 'react';
import { Alert, Button } from 'react-bootstrap';
import './style.scss';
import { COMPONENT_VARIANT } from '../../../constants/const';
import { PublicTransportApi } from '../../../services/Api/PublicTransportApi';

const TransportImagePage = (props) => {
  const refLoadImage = useRef(null);
  const [image, setImage] = useState(null);
  const [alertShow, setAlertShow] = useState(false);
  const [textAlert, setTextAlert] = useState('The link is saved to the clipboard');

  const getAllDescriptionImage = () => {
    PublicTransportApi.getPublicTransportDescriptionImage().then((res) => {
      setImage(res.results.sort((a, b) => (a.id < b.id ? 1 : -1)));
    });
  };
  const UploadDescriptionImage = (image) => {
    PublicTransportApi.loadPublicTransportDescriptionImage(image).then(() => {
      setAlertShow(true);
      setTextAlert('Successful image upload');
      getAllDescriptionImage();
    });
  };
  useEffect(() => {
    if (!image) getAllDescriptionImage();
  }, [props.currencyId]);
  return (
    <div className="description-image-context">
      {alertShow && <Alert severity="success">{textAlert}</Alert>}
      <div className="description-image-upload">
        <Button
          variant={COMPONENT_VARIANT.primary}
          onClick={() => {
            refLoadImage.current.click();
          }}>
          Upload
        </Button>
      </div>
      <div className="description-mass-image">
        {image?.map((value, index) => {
          if (value.imagePath !== null)
            return (
              <div key={index} className="image-content">
                <img className="desctiption-image" src={value.imagePath} alt="img" />
                <Button
                  variant={COMPONENT_VARIANT.link}
                  className="desctiption-image-url"
                  onClick={() => {
                    navigator.clipboard.writeText(value.imagePath);
                    setAlertShow(true);
                    setTextAlert('The link is saved to the clipboard');
                  }}>
                  {value.imagePath.length > 50
                    ? value.imagePath.substring(0, 50) + '...'
                    : value.image}
                </Button>
              </div>
            );
        })}
      </div>
      <input
        ref={refLoadImage}
        type="file"
        name="myImage"
        style={{ display: 'none' }}
        accept="image/jpeg,image/gif,image/png,image/webp,application/pdf,image/x-eps"
        onChange={(event) => {
          console.log(event.target.files[0]);
          if (event.target.files[0]) UploadDescriptionImage(event.target.files[0]);
        }}
      />
    </div>
  );
};

export default TransportImagePage;
