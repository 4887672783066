import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../Loader';
import { COMPONENT_VARIANT, EVENT_AIRPORT, EVENT_PUBLIC_TRANSPORT } from '../../../../constants/const';
import TableSelectDetail from '../../../DetailComponent/TableSelectDetail';
import { getPublicTransport, setPublicTransportAirport } from '../../../../redux/reducers/publicTransport';
import { AirportApi } from '../../../../services/Api/AirportApi';

const PublicTransportSelectAirport = (props) => {
  const stateElement = useSelector((state) => state.publicTransport);
  const [selectedElement, setSelectedElement] = useState(stateElement.airport);

  const dispatch = useDispatch();

  useEffect(() => {
    if ((!stateElement.id || props.id !== stateElement.id) && props.id !== 'new')
      dispatch(getPublicTransport(parseInt(props.id)));
  }, [props.id]);

  useEffect(() => {
    if (stateElement.airport && selectedElement === null) setSelectedElement(stateElement.airport);
  }, [stateElement]);

  const saveData = (selectElement) => {
    dispatch(setPublicTransportAirport(selectElement));
  };

  const onSelect = (element) => {
    setSelectedElement(element);
  };

  const onDelete = () => {
    setSelectedElement(null);
  };
  return (
    <Loader loading={!stateElement.loaded} variant={COMPONENT_VARIANT.dark} withMargin={100}>
      <TableSelectDetail
        title={'Airport'}
        description={'Select Airport companies to add to this public transport and click Save.'}
        titleCard={'otherNames'}
        saveData={saveData}
        request={AirportApi.getAirportPage}
        event={EVENT_AIRPORT}
        navigateEvent={EVENT_PUBLIC_TRANSPORT}
        selectedElement={selectedElement}
        onSelect={onSelect}
        onDelete={onDelete}
        isMultiLanguage
        isOnlySelect
      />
    </Loader>
  );
};

export default PublicTransportSelectAirport;
