import React from 'react';
import PaginationPage from '../../../PaginationPage';
import { DESCRIPTION_PAGE, EVENT_CITY } from '../../../../constants/const';
import { CityApi } from '../../../../services/Api/CityApi';

const CitiesTabs = () => {
  return (
    <div className="tabs-control">
      <PaginationPage
        name={DESCRIPTION_PAGE.city}
        event={EVENT_CITY}
        request={CityApi.getCityPage}
        isDetail
      />
    </div>
  );
};

export default CitiesTabs;
