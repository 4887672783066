import React, { useEffect, useState } from 'react';
import InputCard from '../../InputCard';

import { COMPONENT_VARIANT, PROTOCOL, STORAGE_LANGUAGE } from '../../../constants/const';
import DropDownButton from '../../DropDownButton';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import './style.scss';
import TransitionWebsite from '../../svg/TransitionWebsite';

const InputDetail = (props) => {
  const language = useSelector((state) => state.app.language);
  const activeLanguageStorage = localStorage.getItem(STORAGE_LANGUAGE);
  const [activeLanguage, setActiveLanguage] = useState(activeLanguageStorage);

  const getInitValue = () => {
    if (language) {
      if (props.isWebsite) return props.defaultValue?.url;
      if (props.isLanguage) return props.defaultValue[activeLanguage];
      return props.defaultValue;
    }
    return '';
  };

  const [value, setValue] = useState(getInitValue());
  const [activeProtocol, setActiveProtocol] = useState(
    props.defaultValue?.protocol ?? PROTOCOL.HTTPS
  );

  useEffect(() => {
    setValue(getInitValue());
  }, [props.defaultValue]);

  const getMasLanguage = () => {
    if (language) {
      return language.map((value) => value);
    }
    return [];
  };
  const replaceUrl = (value) => {
    if (value) {
      return props.isWebsite ? value.replace(PROTOCOL.HTTPS, '').replace(PROTOCOL.HTTP, '') : value;
    }
    return value;
  };

  const onBlur = () => {
    console.log(value);
    if (props.isWebsite) {
      props.setField({ protocol: activeProtocol, url: value });
      return;
    }
    if (props.isLanguage) {
      let tmp = { ...props.defaultValue };
      tmp[activeLanguage] = value;

      props.setField(tmp);
    } else {
      props.setField(value);
    }
  };

  const onChangeValue = (value) => {
    if (props.isLanguage) {
      let tmp = props.defaultValue;
      tmp[activeLanguage] = value;
      setValue(value);
    } else {
      if (props.isWebsite) setValue(value.replace(PROTOCOL.HTTPS, '').replace(PROTOCOL.HTTP, ''));
      else setValue(value);
    }
  };

  return (
    <div className="input-detail">
      {props.head && (
        <div className="head-context">
          <div className="head">{props.head}</div>
          {props.isNecessarily && <div className="necessarily">*</div>}
        </div>
      )}
      {props.isWebsite && (
        <DropDownButton
          className={'dropdown-website'}
          activeElement={activeProtocol}
          setActiveElement={(protocol) => {
            setActiveProtocol(protocol);
            props.setField({
              protocol: protocol,
              url: value
            });
          }}
          element={[PROTOCOL.HTTP, PROTOCOL.HTTPS]}
          variant="secondary"></DropDownButton>
      )}
      {props.isSubtitle && <div className={'subtitle'}>{props.subtitle}</div>}
      <div className={'input-lang-wrapper'}>
        {props.isInput && (
          <InputCard
            placeholder={props.placeholder}
            validate={props.validate}
            value={replaceUrl(value) === 'null' ? '' : replaceUrl(value)}
            onBlur={onBlur}
            isDisabled={props.isDisabled}
            onChange={(e) => onChangeValue(e.target.value)}
            isMultiline={props.isMultiline}
            isWebsite={props.isWebsite}
            isNumber={props.isNumber}
            isSubtitle={props.isSubtitle}
          />
        )}
        {props.isWebsite && (
          <Button
            variant={COMPONENT_VARIANT.secondary}
            className="select-website"
            disabled={!props.defaultValue.url}
            onClick={() => {
              window.open(
                props.defaultValue.protocol + props.defaultValue.url,
                '_blank',
                'noopener,noreferrer'
              );
            }}>
            <TransitionWebsite />
          </Button>
        )}
        {props.isLanguage && (
          <DropDownButton
            className={'dropdown-language'}
            activeElement={activeLanguage}
            setActiveElement={(element) => {
              setValue(props.defaultValue[element]);
              setActiveLanguage(element);
            }}
            element={getMasLanguage()}
            variant="secondary"></DropDownButton>
        )}
      </div>
    </div>
  );
};

export default InputDetail;
