import { instance } from "../AuthApi";

export const PushEventApi = { 
  getPushEvent() {
    return instance.get('admin/message/phrase').then((res) => res.data);
  },

  getPushEventId(id) {
    return instance.get('admin/message/phrase/' + id).then((res) => res.data);
  },
  
  updatePushEventId(id, data) {
    return instance
      .put('admin/message/phrase/' + id, data)
      .then((res) => {
        return res.data;
      })
      .catch((e) => e);
  },
  createLanguagePushEventId(id, language, text) {
    return instance
      .post('admin/message/language/work/' + id, { language: language, text: text })
      .then((res) => {
        return res.data;
      })
      .catch((e) => e);
  },
  deleteLanguagePushEventId(id, language) {
    return instance
      .delete('admin/message/language/work/' + id, { data: { language: language } })
      .then((res) => {
        return res.data;
      })
      .catch((e) => e);
  },

  deletePushEventId(id) {
    return instance.delete('admin/message/phrase/' + id).then((res) => res.data);
  }
};
