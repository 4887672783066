import { AirportApi } from '../services/Api/AirportApi';
import { CityApi } from '../services/Api/CityApi';
import { CountryApi } from '../services/Api/CountryApi';
import { EVENT_AIRPORT, EVENT_CITY, EVENT_COUNTRY } from './const';

export const FILTER_TYPE = {
  string: 'string',
  dropDown: 'dropdown',
  selectElement: 'selectElement'
};
export const Q_PARAM_SEARCH = { search: 'search', page: 'page' };

export const SORT_FIELD = {
  airports: ['popularity', 'updated', 'lastChecked'],
  airport_map: null,
  airlines: null,
  city: null,
  country: null,
  currency: null,
  taxis: null
};

export const FILTER_FIELD = {
  airports: { country: null, city: null, responsible: null },
  airport_map: null,
  airlines: null,
  public_transport: { airport: null },
  city: null,
  country: null,
  currency: null,
  taxis: null
};

export const FILTER_FIELD_TYPE = {
  airports: {
    country: {
      type: FILTER_TYPE.selectElement,
      event: EVENT_COUNTRY,
      request: CountryApi.getCountryPage,
      requestElement: CountryApi.getCountryId
    },
    city: {
      type: FILTER_TYPE.selectElement,
      event: EVENT_CITY,
      request: CityApi.getCityPage,
      requestElement: CityApi.getCityId
    },
    responsible: {
      type: FILTER_TYPE.dropDown,
      value: ['a.sorokin', 'g.gozha', 'v.sosnouski', 'm.mamedov', 'y.miller']
    }
  },
  airport_map: null,
  public_transport: {
    airport: {
      type: FILTER_TYPE.selectElement,
      event: EVENT_AIRPORT,
      request: AirportApi.getAirportPage,
      requestElement: AirportApi.getAirportId
    }
  },
  city: null,
  country: null,
  currency: null,
  taxis: null
};

export const FILTER_BOOLEAN = {
  airports: ['taxi', 'map', 'transport'],
  airport_map: null,
  public_transport: null,
  city: ['ko', 'de', 'ja', 'es', 'fr', 'en', 'ru'],
  country: ['ko', 'de', 'ja', 'es', 'fr', 'en', 'ru'],
  currency: null,
  taxis: null
};
