import { instance } from "../AuthApi";

export const CountryApi = {
  getCountryPage(page, count, search = null, filter = null, sort = null, filterBoolean = null) {
    const params = {
      ...filter,
      page: page,
      search: search ?? '',
      filter: filterBoolean ?? '',
      ordering: sort ?? ''
    };
    return instance.get('admin/country', { params: params }).then((res) => res.data);
  },
  getCountryId(id) {
    return instance.get('admin/country/' + id).then((res) => res.data);
  },
  createCountry(data) {
    return instance
      .post('admin/country', data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then((res) => res.data)
      .catch((e) => e);
  },
  updateCountry(id, data) {
    return instance
      .put('admin/country/' + id, data)
      .then((res) => res.data)
      .catch((e) => e);
  },
  deleteCountry(id) {
    return instance.delete('admin/country/' + id).then((res) => res.data);
  }
};
