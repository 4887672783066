import { instance } from "../AuthApi";

export const WeatherStateApi = {  
  getWeatherStatePage() {
    return instance.get('admin/weather').then((res) => res.data);
  },
  createWeatherStateLocalization(data) {
    return instance
      .post('admin/weather/description/', data)
      .then((res) => {
        return res.data;
      })
      .catch((e) => e);
  },
  updateWeatherState(id, data) {
    return instance
      .put('admin/weather/description/' + id, data)
      .then((res) => {
        return res.data;
      })
      .catch((e) => e);
  }
};
