import { instance } from '../AuthApi';

export const AirlinesApi = {
  getAirlinesPage(page, count, search = null, filter = null, sort = null, filterBoolean = null) {
    const params = {
      ...filter,
      page: page,
      query: search ?? '',
      filter: filterBoolean ?? '',
      ordering: sort ?? ''
    };
    return instance
      .get('airline', {
        params: params
      })
      .then((res) => {
        return { results: res.data.payload.airlines, count: res.data.payload.count };
      });
  },

  getAirlinesId(id) {
    return instance.get('airline/' + id).then((res) => {
      return res.data.payload;
    });
  },
  createAirlines(data) {
    return instance
      .post('airline', data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then((res) => {
        return res.data;
      })
      .catch((e) => e);
  },
  updateAirlines(id, data) {
    return instance
      .put('airline/' + id, data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then((res) => {
        return res.data;
      })
      .catch((e) => e);
  },
  updateCheckedAirlines(id) {
    return instance.get('admin/airline/update/' + id).then((res) => res.data);
  },
  deleteAirlines(id) {
    return instance.delete('admin/airline/' + id).then((res) => res.data);
  }
};
