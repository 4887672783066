import React, { useEffect, useState } from 'react';
import { Alert, Button, Form } from 'react-bootstrap';
import { isAuthorization } from '../services/storage';
import { useNavigate } from 'react-router-dom';
import { EVENT_AIRPORT } from '../constants/const';
import './style.scss';
import { useDispatch } from 'react-redux';
import { getLanguage } from '../redux/reducers/app';
import Loader from '../Components/Loader';
import { AuthApi } from '../services/AuthApi';

const Authorization = () => {
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    if (isAuthorization()) navigate(EVENT_AIRPORT);
  }, []);

  return (
    <div className={'authorization'}>
      <div className={'authorization-context'}>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label className="label">Login</Form.Label>
          <Form.Control
            className="controller"
            placeholder="Enter login"
            onChange={(e) => {
              setLogin(e.target.value);
            }}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label className="label">Password</Form.Label>
          <Form.Control
            className="controller"
            type="password"
            placeholder="Password"
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
        </Form.Group>
        <Button
          className="button"
          variant="primary"
          type="button"
          onClick={() => {
            setIsLoading(true);
            AuthApi.getProfile(login, password)
              .then(() => {
                setIsError(false);
                setIsLoading(false);
                dispatch(getLanguage());
                navigate(EVENT_AIRPORT);
              })
              .catch(() => {
                setIsLoading(false);
                setIsError(true);
              });
          }}>
          <Loader loading={isLoading} small isAuthorization>
            {'Submit'}
          </Loader>
        </Button>
        {isError && <Alert variant="danger">Error. Invalid username or password.</Alert>}
      </div>
    </div>
  );
};

export default Authorization;
